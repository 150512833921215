import React, {useState} from 'react';

import {Button} from '../Button';
import {IconView} from '../IconView';
import {Pressable} from '../Pressable';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {AlertTypeConfig} from './config/index';
import {AlertProps} from './types';

export const Alert = ({
  title,
  description,
  type = 'info',
  icon,
  onAction,
  onDismiss,
  actionLabel,
  dissmissLabel,
  ...props
}: AlertProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const {color, backgroundColor, icon: defaultIcon} = AlertTypeConfig[type];
  const iconToUse = icon ?? defaultIcon;
  const showCircles = !icon;

  const onDismissClick = () => {
    setIsDismissed(true);
    onDismiss?.();
  };

  return (
    <View
      display={isDismissed ? 'none' : 'flex'}
      padding="$5"
      borderRadius="$rounded-lg"
      gap="$3"
      borderColor="$border-primary"
      backgroundColor={backgroundColor}
      borderWidth={1}
      {...props}>
      <XStack alignItems="flex-start" gap="$3">
        <View alignItems="center" justifyContent="center">
          {showCircles && (
            <>
              <View
                position="absolute"
                width={25}
                height={25}
                borderRadius={100}
                borderWidth={2}
                borderColor={color}
                opacity={0.3}
              />
              <View
                position="absolute"
                width={35}
                height={35}
                borderRadius={100}
                borderWidth={2}
                borderColor={color}
                opacity={0.2}
              />
            </>
          )}
          <View padding={6} alignItems="center" justifyContent="center">
            <IconView size={16} icon={iconToUse} color={color} />
          </View>
        </View>

        <YStack flex={1} gap="$2">
          <XStack alignItems="center" gap="$2">
            <Typography fontSize="$text-sm" color="$fg-primary" fontWeight="600">
              {title}
            </Typography>
          </XStack>

          <Typography fontSize="$text-sm" color="$fg-secondary" fontWeight="400">
            {description}
          </Typography>

          {(onAction || onDismiss) && (
            <XStack gap="$6" marginTop="$2">
              {onDismiss && (
                <Button onPress={onDismissClick} hierarchy="link-gray" size="sm">
                  {dissmissLabel || <CoreTrans i18nKey="action.cancel" />}
                </Button>
              )}
              {onAction && (
                <Button onPress={onAction} hierarchy="link-color" size="sm">
                  {actionLabel || <CoreTrans i18nKey="action.ok" />}
                </Button>
              )}
            </XStack>
          )}
        </YStack>

        <Pressable onPress={onDismissClick}>
          <IconView color="$fg-quinary" size={20} icon="Cancel01Icon" />
        </Pressable>
      </XStack>
    </View>
  );
};

import React, {forwardRef, ReactNode} from 'react';

import {ImageSourcePropType} from 'react-native';
import {Avatar as BaseAvatar, SquareProps} from 'tamagui';

import {IconView} from '../IconView';
import {Text} from '../Text';
import {View} from '../View';

import {avatarSizes, AvatarSizes, FALLBACK_SCALE} from './Constant';

type BorderStatus = true | false;

export type AvatarProps = Omit<SquareProps, 'size' | 'circular'> & {
  size?: AvatarSizes;
  source?: ImageSourcePropType;
  fallback?: ReactNode;
  statusIcon?: ReactNode;
  hasStatusBorder?: BorderStatus;
  name?: string;
};

/**
 * Renders an avatar component.
 *
 * @component
 * @example
 * ```tsx
 * <Avatar
 *   size="sm"
 *   source={avatarImage}
 *   hasStatusBorder={false}
 *   statusIcon={() => <UserComponent />}
 *   name="Mohamed"
 * />
 * ```
 */

const _Avatar = (
  {size = 'sm', source, fallback, hasStatusBorder = false, statusIcon, name, ...props}: AvatarProps,
  ref,
) => {
  const selectedSize = avatarSizes[size];
  const fallbackSize = selectedSize.size * FALLBACK_SCALE;
  const statusIndicatorSize = Math.max(selectedSize.size * 0.25, 8);

  const renderFallbackContent = () => {
    if (name) {
      return (
        <Text
          lineHeight={selectedSize.size * 0.5 + 4}
          color="$fg-quaternary"
          fontWeight="600"
          fontSize={selectedSize.size * 0.5}>
          {name.charAt(0).toUpperCase()}
          {name.length > 1 && name.charAt(1).toUpperCase()}
        </Text>
      );
    }
    return fallback || <IconView icon="UserIcon" color="$fg-quaternary" size={fallbackSize} />;
  };

  return (
    <View
      style={{
        position: 'relative',
        width: selectedSize.size + selectedSize.size * 0.1,
        height: selectedSize.size + selectedSize.size * 0.1,
        backgroundColor: '$avatar-bg',
      }}>
      <BaseAvatar
        outlineColor="$bg-primary"
        outlineStyle="solid"
        outlineWidth={1.5}
        circular={true}
        borderWidth={selectedSize.borderWidth}
        borderColor="$border-secondary"
        size={selectedSize.size}
        ref={ref}
        {...props}>
        {source && <BaseAvatar.Image source={source} />}
        <BaseAvatar.Fallback backgroundColor="$avatar-bg" alignItems="center" justifyContent="center">
          {renderFallbackContent()}
        </BaseAvatar.Fallback>
      </BaseAvatar>
      <View
        zIndex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="$rounded-full"
        borderColor="$bg-primary"
        position="absolute"
        bottom={statusIndicatorSize / 2}
        right={statusIndicatorSize / 2}
        width={statusIndicatorSize}
        height={statusIndicatorSize}
        backgroundColor={hasStatusBorder ? '$fg-success-secondary' : ''}
        borderWidth={hasStatusBorder ? 1.5 : 0}>
        {statusIcon}
      </View>
    </View>
  );
};

export const Avatar = forwardRef(_Avatar);

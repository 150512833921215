import React from 'react';

import {ColorTokens} from 'tamagui';

import {useTokenColor} from '../../../utils';
import {SocialButtonTheme, SocialIconState, SvgSocialIconProps} from '../types';

export const AppleIcon: React.FC<SvgSocialIconProps> = ({theme = 'brand', state = 'default', ...props}) => {
  const _themestateColors: {
    [theme in SocialButtonTheme]?: {[state in SocialIconState]?: ColorTokens};
  } = {
    gray: {
      default: '$fg-quaternary',
      hover: '$fg-quaternary-hover',
    },
    brand: {
      default: '$fg-white',
      hover: '$fg-white',
    },
    color: {
      default: '$social-icon-fg-apple',
      hover: '$social-icon-fg-apple',
    },
  };
  const _stateColors = _themestateColors?.[theme];
  const _colorToken = _stateColors?.[state];
  const _iconColor = useTokenColor(_colorToken ? _colorToken : '$fg-white');

  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.8426 17.1449C18.5099 17.9135 18.1161 18.6211 17.6598 19.2715C17.0378 20.1583 16.5286 20.7721 16.1361 21.113C15.5277 21.6724 14.8759 21.959 14.1779 21.9753C13.6768 21.9753 13.0725 21.8327 12.3691 21.5434C11.6633 21.2555 11.0148 21.113 10.4217 21.113C9.79978 21.113 9.13274 21.2555 8.41926 21.5434C7.70469 21.8327 7.12904 21.9834 6.68892 21.9984C6.01957 22.0269 5.35239 21.7322 4.68644 21.113C4.26139 20.7422 3.72974 20.1067 3.09285 19.2063C2.40951 18.2449 1.84772 17.13 1.4076 15.8589C0.93624 14.486 0.699951 13.1565 0.699951 11.8694C0.699951 10.3951 1.01853 9.12345 1.65665 8.05784C2.15815 7.20191 2.82533 6.52672 3.66035 6.03105C4.49537 5.53539 5.39761 5.2828 6.36925 5.26664C6.9009 5.26664 7.59809 5.43109 8.46448 5.75429C9.32843 6.07858 9.88316 6.24303 10.1264 6.24303C10.3082 6.24303 10.9245 6.05074 11.9692 5.66738C12.9571 5.31186 13.7909 5.16466 14.474 5.22264C16.3249 5.37202 17.7155 6.10167 18.6402 7.41619C16.9849 8.4192 16.166 9.82403 16.1823 11.6262C16.1972 13.03 16.7065 14.1981 17.7073 15.1256C18.1609 15.5561 18.6674 15.8888 19.231 16.1251C19.1087 16.4795 18.9797 16.819 18.8426 17.1449ZM14.5975 0.440369C14.5975 1.54062 14.1956 2.56792 13.3944 3.51878C12.4275 4.64917 11.258 5.30236 9.98976 5.19929C9.9736 5.06729 9.96423 4.92837 9.96423 4.78239C9.96423 3.72615 10.424 2.59576 11.2406 1.67152C11.6483 1.20356 12.1667 0.814453 12.7955 0.504058C13.4229 0.198295 14.0163 0.0292007 14.5744 0.000244141C14.5907 0.147331 14.5975 0.294426 14.5975 0.440355V0.440369Z"
        fill={_iconColor}
      />
    </svg>
  );
};

import {ButtonProps} from '../Button';

import {Social, SocialButtonTheme} from './types';

export const getSocialButtonStyle = (theme: SocialButtonTheme, social: Social, disabled?: boolean): ButtonProps => {
  const _defaultStyles: ButtonProps = {
    backgroundColor: '$bg-primary',
    borderColor: '$border-primary',
    color: '$text-secondary',
    hoverStyle: {
      backgroundColor: '$bg-primary-hover',
      borderColor: '$border-primary',
    },
    focusStyle: {
      backgroundColor: '$bg-primary-hover',
      borderColor: '$border-primary',
    },
    focusVisibleStyle: {
      backgroundColor: '$bg-primary-hover',
      borderColor: '$border-primary',
    },
  };

  const _diabledStyles = {
    color: '$fg-disabled',
    borderColor: '$border-disabled-subtle',
    backgroundColor: '$bg-disabled',
  };

  const _styles: {[key in Social]?: {[theme in SocialButtonTheme]?: ButtonProps}} = {
    facebook: {
      brand: {
        backgroundColor: '#1877F2',
        borderColor: '#1877F2',
        color: '$text-white',
        hoverStyle: {
          backgroundColor: '#0C63D4',
          borderColor: '#0C63D4',
        },
        focusStyle: {
          backgroundColor: '#0C63D4',
          borderColor: '#0C63D4',
        },
        focusVisibleStyle: {
          backgroundColor: '#0C63D4',
          borderColor: '#0C63D4',
        },
      },
    },
    apple: {
      brand: {
        backgroundColor: '$black',
        borderColor: '$black',
        color: '$text-white',
        hoverStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusVisibleStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
      },
    },
    dribble: {
      brand: {
        backgroundColor: '#EA4C89',
        borderColor: '#EA4C89',
        color: '$text-white',
        hoverStyle: {
          backgroundColor: '#E62872',
          borderColor: '#EA4C89',
        },
        focusStyle: {
          backgroundColor: '#E62872',
          borderColor: '#EA4C89',
        },
        focusVisibleStyle: {
          backgroundColor: '#E62872',
          borderColor: '#EA4C89',
        },
      },
    },
    figma: {
      brand: {
        backgroundColor: '$black',
        borderColor: '$black',
        color: '$text-white',
        hoverStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusVisibleStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
      },
    },
    x: {
      brand: {
        backgroundColor: '$black',
        borderColor: '$black',
        color: '$text-white',
        hoverStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
        focusVisibleStyle: {
          backgroundColor: '$black',
          borderColor: '$black',
        },
      },
    },
  };

  const _socialStyle = _styles?.[social];

  return {..._defaultStyles, ..._socialStyle?.[theme], ...(disabled && _diabledStyles)};
};

import {useState, useCallback, useEffect} from 'react';

import {addDays, isAfter, isBefore, isSameDay, isSameMonth, isWithinRange} from '../utils/date';

import {
  getInitialMonths,
  getNextActiveMonth,
  isDateSelected as isDateSelectedFn,
  isDateBlocked as isDateBlockedFn,
  isFirstOrLastSelectedDate as isFirstOrLastSelectedDateFn,
  isEndDate as isEndDateFn,
  isStartDate as isStartDateFn,
  canSelectRange,
  isDateHovered as isDateHoveredFn,
  isInUnavailableDates,
} from './useDatePicker.utils';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export type FocusedInput = 'startDate' | 'endDate' | null;

export interface OnDatesChangeProps {
  focusedInput: FocusedInput;
  startDate: Date | null;
  endDate: Date | null;
}

export type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface UseDatepickerProps {
  onDatesChange(data: OnDatesChangeProps): void;
  minBookingDate?: Date;
  maxBookingDate?: Date;
  startDate: Date | null;
  endDate: Date | null;
  focusedInput: FocusedInput;
  numberOfMonths?: number;
  minSelectedDays?: number;
  exactMinSelectedDays?: boolean;
  firstDayOfWeek?: FirstDayOfWeek;
  initialVisibleMonth?: Date;
  isDateBlocked?(date: Date): boolean;
  unavailableDates?: Date[];
  changeActiveMonthOnSelect?: boolean;
}

export function useDatepicker({
  startDate,
  endDate,
  focusedInput,
  minBookingDate,
  maxBookingDate,
  onDatesChange,
  initialVisibleMonth,
  exactMinSelectedDays = false,
  minSelectedDays = 1,
  numberOfMonths = 2,
  firstDayOfWeek = 1,
  isDateBlocked: isDateBlockedProps = () => false,
  unavailableDates = [],
  changeActiveMonthOnSelect = true,
}: UseDatepickerProps) {
  const [activeMonths, setActiveMonths] = useState(() =>
    startDate
      ? getInitialMonths(numberOfMonths, startDate)
      : getInitialMonths(numberOfMonths, initialVisibleMonth || null),
  );
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [focusedDate, setFocusedDate] = useState<Date | null>(startDate);

  useEffect(() => {
    setActiveMonths(
      startDate
        ? getInitialMonths(numberOfMonths, startDate)
        : getInitialMonths(numberOfMonths, initialVisibleMonth || null),
    );
  }, [numberOfMonths]);

  const disabledDatesByUser = (date: Date) => {
    return isInUnavailableDates(unavailableDates, date) || isDateBlockedProps(date);
  };

  const isDateSelected = (date: Date) => isDateSelectedFn(date, startDate, endDate);

  const isFirstOrLastSelectedDate = (date: Date) => isFirstOrLastSelectedDateFn(date, startDate, endDate);

  const isStartDate = (date: Date) => isStartDateFn(date, startDate);

  const isEndDate = (date: Date) => isEndDateFn(date, endDate);

  const isDateBlocked = (date: Date) =>
    isDateBlockedFn({
      date,
      minBookingDate,
      maxBookingDate,
      startDate,
      endDate,
      minSelectedDays,
      isDateBlockedFn: disabledDatesByUser,
    });

  const isDateFocused = (date: Date) => (focusedDate ? isSameDay(date, focusedDate) : false);

  const isDateHovered = (date: Date) =>
    isDateHoveredFn({
      date,
      hoveredDate,
      startDate,
      endDate,
      minSelectedDays,
      exactMinSelectedDays,
      isDateBlocked: disabledDatesByUser,
    });

  function onResetDates() {
    onDatesChange({
      startDate: null,
      endDate: null,
      focusedInput: START_DATE,
    });
  }

  function onDateSelect(date: Date) {
    if (
      (focusedInput === END_DATE || focusedInput === START_DATE) &&
      minSelectedDays > 0 &&
      exactMinSelectedDays &&
      canSelectRange({
        minSelectedDays,
        exactMinSelectedDays,
        minBookingDate,
        maxBookingDate,
        isDateBlocked: disabledDatesByUser,
        startDate: date,
        endDate: null,
      })
    ) {
      onDatesChange({
        startDate: date,
        endDate: addDays(date, minSelectedDays - 1),
        focusedInput: null,
      });
    } else if (
      ((focusedInput === END_DATE && startDate && isBefore(date, startDate)) ||
        (focusedInput === START_DATE && endDate && isAfter(date, endDate))) &&
      !exactMinSelectedDays &&
      canSelectRange({
        minSelectedDays,
        isDateBlocked: disabledDatesByUser,
        startDate: date,
        endDate: null,
      })
    ) {
      onDatesChange({
        endDate: null,
        startDate: date,
        focusedInput: END_DATE,
      });
    } else if (
      focusedInput === START_DATE &&
      !exactMinSelectedDays &&
      canSelectRange({minSelectedDays, isDateBlocked: disabledDatesByUser, endDate, startDate: date})
    ) {
      onDatesChange({
        endDate,
        startDate: date,
        focusedInput: END_DATE,
      });
    } else if (
      focusedInput === START_DATE &&
      !exactMinSelectedDays &&
      canSelectRange({
        minSelectedDays,
        isDateBlocked: disabledDatesByUser,
        endDate: null,
        startDate: date,
      })
    ) {
      onDatesChange({
        endDate: null,
        startDate: date,
        focusedInput: END_DATE,
      });
    } else if (
      focusedInput === END_DATE &&
      startDate &&
      !isBefore(date, startDate) &&
      !exactMinSelectedDays &&
      canSelectRange({minSelectedDays, isDateBlocked: disabledDatesByUser, startDate, endDate: date})
    ) {
      onDatesChange({
        startDate,
        endDate: date,
        focusedInput: null,
      });
    }

    if (
      focusedInput !== END_DATE &&
      (!focusedDate || (focusedDate && !isSameMonth(date, focusedDate))) &&
      changeActiveMonthOnSelect
    ) {
      setActiveMonths(getInitialMonths(numberOfMonths, date));
    }
  }

  function onDateHover(date: Date | null) {
    if (!date) {
      setHoveredDate(null);
    } else if (date) {
      const isNotBlocked = !isDateBlocked(date) || (startDate && isSameDay(date, startDate));
      const isHoveredDateAfterOrEqualMinDate = minBookingDate ? !isBefore(date, addDays(minBookingDate, -1)) : true;
      const isHoveredDateBeforeOrEqualMaxDate = maxBookingDate ? !isAfter(date, maxBookingDate) : true;

      // Exact minimal booking days
      const potentialEndDate = addDays(date, minSelectedDays - 1);
      const isPotentialEndDateAfterOrEqualMinDate = minBookingDate ? !isBefore(potentialEndDate, minBookingDate) : true;
      const isPotentialEndDateBeforeOrEqualMaxDate = maxBookingDate ? !isAfter(potentialEndDate, maxBookingDate) : true;
      const isExactAndInRange =
        exactMinSelectedDays &&
        minSelectedDays > 1 &&
        isHoveredDateAfterOrEqualMinDate &&
        isHoveredDateBeforeOrEqualMaxDate &&
        isPotentialEndDateAfterOrEqualMinDate &&
        isPotentialEndDateBeforeOrEqualMaxDate;

      // Is date in range
      const isInRange =
        startDate &&
        !endDate &&
        !exactMinSelectedDays &&
        isHoveredDateAfterOrEqualMinDate &&
        isHoveredDateBeforeOrEqualMaxDate;

      // Is start date hovered and in range
      const isMinSelectedDaysInRange =
        minSelectedDays > 1 && startDate
          ? isWithinRange(date, {start: startDate, end: addDays(startDate, minSelectedDays - 2)})
          : true;
      const isStartDateHoveredAndInRange = startDate && isSameDay(date, startDate) && isMinSelectedDaysInRange;

      if (isNotBlocked && (isExactAndInRange || isInRange || isStartDateHoveredAndInRange)) {
        setHoveredDate(date);
      } else if (hoveredDate !== null) {
        setHoveredDate(null);
      }
    }
  }

  const goToPreviousMonths = useCallback(() => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, -1));
    setFocusedDate(null);
  }, [activeMonths, numberOfMonths]);

  const goToPreviousMonthsByOneMonth = useCallback(() => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, -1, 1));
    setFocusedDate(null);
  }, [activeMonths, numberOfMonths]);

  const goToNextMonths = useCallback(() => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, 1));
    setFocusedDate(null);
  }, [activeMonths, numberOfMonths]);

  const goToNextMonthsByOneMonth = useCallback(() => {
    setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, 1, 1));
    setFocusedDate(null);
  }, [activeMonths, numberOfMonths]);

  const goToDate = useCallback(
    (date: Date) => {
      setActiveMonths(getInitialMonths(numberOfMonths, date));
      setFocusedDate(null);
    },
    [numberOfMonths],
  );

  const goToPreviousYear = useCallback(
    (numYears = 1) => {
      setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, -(numYears * 12 - numberOfMonths + 1)));
      setFocusedDate(null);
    },
    [activeMonths, numberOfMonths],
  );

  const goToNextYear = useCallback(
    (numYears = 1) => {
      setActiveMonths(getNextActiveMonth(activeMonths, numberOfMonths, numYears * 12 - numberOfMonths + 1));
      setFocusedDate(null);
    },
    [activeMonths, numberOfMonths],
  );

  return {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isStartDate,
    isEndDate,
    isDateBlocked,
    numberOfMonths,
    isDateFocused,
    focusedDate,
    hoveredDate,
    onResetDates,
    onDateHover,
    onDateSelect,
    goToPreviousMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonths,
    goToNextMonthsByOneMonth,
    goToDate,
    goToPreviousYear,
    goToNextYear,
  };
}

import React from 'react';

import {View} from 'react-native';

import {Avatar, AvatarProps} from '../Avatar';
import {AvatarSizes, avatarSizes} from '../Avatar/Constant';
import {IconView} from '../IconView';
import {Typography, TypographyProps} from '../Typography';
import {View as NewView} from '../View';

export type AvatarGroupProps = {
  size: AvatarProps['size'];
  avatars: Omit<AvatarProps, 'size'>[];
  maxAvatars?: number;
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({avatars = [], size = 'md', maxAvatars = 20}) => {
  const remainingAvatars = avatars.length - maxAvatars;
  const displayedAvatars = avatars.slice(0, maxAvatars);
  const avatarSize = avatarSizes[size];

  if (maxAvatars <= 0) {
    return null;
  }
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      {displayedAvatars.map((avatar, index) => (
        <View key={index} style={{marginLeft: index > 0 ? -avatarSize.size / 2 : 0, backgroundColor: '$avatar-bg'}}>
          <Avatar {...avatar} size={size} />
        </View>
      ))}
      {remainingAvatars > 0 && (
        <View style={{flexDirection: 'row', alignItems: 'center', gap: 8}}>
          <View style={{marginLeft: -avatarSize.size / 2, backgroundColor: '$avatar-bg'}}>
            <Avatar
              size={size}
              fallback={
                <Typography color="$text-tertiary" variant={typographyVariants[size]}>
                  +{remainingAvatars}
                </Typography>
              }
            />
          </View>
          <NewView
            width={avatarSize.size}
            height={avatarSize.size}
            backgroundColor="$bg-primary"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            borderColor="$border-primary"
            borderStyle="dashed"
            borderRadius="$rounded-full">
            <IconView icon="PlusSignIcon" size={20} color="$fg-quinary" />
          </NewView>
        </View>
      )}
    </View>
  );
};

const typographyVariants: Record<AvatarSizes, TypographyProps['variant']> = {
  xs: 'caption',
  sm: 'body2',
  md: 'body1',
  lg: 'h6',
  xl: 'h6',
  '2xl': 'h5',
};

import React, {useState} from 'react';

import {Checkbox} from '../Checkbox';
import {ListCardItem} from '../ListCardItem';
import {View} from '../View';
import {YStack} from '../YStack';

import {CheckboxGroupProps} from './types';

export * from './types';

export function CheckboxGroup({
  defaultValue = [],
  value = [],
  items,
  onChange,
  size,
  disabled = false,
  containerProps,
}: CheckboxGroupProps) {
  const [selected, setSelected] = useState(value || defaultValue);

  const onItemCheckedChange = (val: string, checked: boolean) => {
    const newSelected = checked ? [...selected, val] : selected.filter((v) => v !== val);
    setSelected(newSelected);
    onChange?.(newSelected);
  };

  return (
    <YStack gap="$spacing-xl" {...containerProps}>
      {items?.map((item, index) => {
        const {title, subTitle, description, prefix, value, disabled: itemDisabled, ...rest} = item;
        const checked = selected.includes(value || '');

        const renderCheckbox = (
          <Checkbox
            backgroundColor="$bg-secondary"
            checked={checked}
            onCheckedChange={(checked) => onItemCheckedChange(value || '', checked)}
            disabled={disabled || itemDisabled}
            value={value}
            size={size}
            {...rest}
          />
        );
        const key = `${value}-${index}`;

        return (
          <View key={key}>
            <ListCardItem
              title={title}
              subTitle={subTitle}
              description={description}
              prefix={prefix ? prefix : renderCheckbox}
              suffix={!!prefix ? renderCheckbox : null}
              size={size}
              checked={checked}
              disabled={disabled || itemDisabled}
            />
          </View>
        );
      })}
    </YStack>
  );
}

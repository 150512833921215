import React from 'react';

import {IconType} from '../../../../types';
import {Button, ButtonProps} from '../../../Button';
import {IconView} from '../../../IconView';
import {Tooltip} from '../../../Tooltip';
import {Typography} from '../../../Typography';
import {View} from '../../../View';
import {XStack} from '../../../XStack';

export type SelectButtonsOptions = {
  options: {icon: IconType; lable: string; optionValue: string; action: ButtonProps['onPress']}[];
  value?: string | number;
};

export const SelectButtons = ({options}: SelectButtonsOptions) => {
  return (
    <XStack gap="$1">
      {options.map(({icon, lable, action}) => {
        return (
          <View flex={1} key={lable}>
            <Tooltip
              allowFlip
              content={
                <View backgroundColor="$gray-900" padding="$1" borderRadius="$rounded">
                  <Typography color="$white">{lable}</Typography>
                </View>
              }
              placement="top">
              <Button
                width="100%"
                hierarchy="secondary-gray"
                flex={1}
                prefix={<IconView icon={icon} />}
                onPress={(e) => {
                  action?.(e);
                }}
              />
            </Tooltip>
          </View>
        );
      })}
    </XStack>
  );
};

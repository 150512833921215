import {useCallback, useMemo, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {SelectProps} from '../types';

import {useSelectIsOpen} from './useSelectIsOpen';
import {useSelectSearch} from './useSelectSearch';

export const useSelect = ({
  open,
  value,
  searchValue: externalSearchValue,
  options,
  showSearch,
  onOpenChange,
  onChange,
  onSearch: externalOnSearch,
}: SelectProps) => {
  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);

  const {t, i18n} = useTranslation();
  const inputRef = useRef<any>();

  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);

  const {filterOptions, filteredOptions, searchValue, onSearch} = useSelectSearch({
    searchValue: externalSearchValue,
    options,
    showSearch,
    onSearch: externalOnSearch,
  });

  const {isOpen, onOpen} = useSelectIsOpen({
    open,
    onOpenChange,
    onClose: () => {
      onSearch?.('');
      inputRef?.current?.blur();
    },
  });

  const selectedOption = useMemo(
    () => options?.find((option) => option.value === selectedValue),
    [options, selectedValue],
  );

  const handleValueChange = useCallback(
    (newValue: string) => {
      setSelectedValue(newValue);
      onChange?.(newValue);

      onOpen(false);
    },
    [onChange],
  );

  return {
    filterOptions,
    isOpen,
    handleOpenChange: onOpen,
    handleValueChange,
    selectedOption,
    filteredOptions,
    searchValue,
    onSearch,
    selectedValue,
    inputRef,
    t,
    i18n,
    triggerWidth,
    setTriggerWidth,
  };
};

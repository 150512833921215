import React from 'react';

import {RadioGroup as BaseRadioGroup, styled} from 'tamagui';

import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {RadioContainerProps, RadioProps} from './types';
export * from './types';

const StyledRadioItem = styled(BaseRadioGroup.Item, {
  backgroundColor: '$bg-primary',
  borderWidth: 1,

  variants: {
    size: {
      sm: {
        width: 16,
        height: 16,
      },
      md: {
        width: 20,
        height: 20,
      },
      lg: {
        width: 25,
        height: 25,
      },
    },
    disabled: {
      true: {
        borderColor: '$gray-300',
      },
      false: {
        borderColor: '$border-primary',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const RadioContainer = ({defaultValue, children, disabled, ...props}: RadioContainerProps) => {
  return (
    <BaseRadioGroup defaultValue={defaultValue} name="form" disabled={disabled} {...props}>
      {children}
    </BaseRadioGroup>
  );
};

export const Radio = ({size = 'md', value, disabled, text, supportText}: RadioProps) => {
  const id = `radiogroup-${value}`;

  return (
    <>
      <XStack gap={3}>
        <XStack
          focusStyle={{borderColor: '$brand-600', borderWidth: 2, height: 'fit-content'}}
          borderRadius="$rounded-full"
          margin="$1"
          justifyContent="center"
          borderWidth={0}
          borderStyle="unset"
          tabIndex={0}>
          <StyledRadioItem value={value} id={id} size={size} disabled={disabled}>
            <BaseRadioGroup.Indicator
              borderColor={disabled ? '$gray-300' : '$brand-600'}
              borderWidth="$1.5"
              padding="$1"
              backgroundColor={disabled ? '$gray-50' : '$bg-primary'}
            />
          </StyledRadioItem>
        </XStack>
        {(text || supportText) && (
          <YStack>
            {text && (
              <Typography fontSize="$16" color="$text-secondary">
                {text}
              </Typography>
            )}
            {supportText && (
              <Typography fontSize="$16" color="$text-tertiary">
                {supportText}
              </Typography>
            )}
          </YStack>
        )}
      </XStack>
    </>
  );
};

import {IconProps} from '../../types';
import {ButtonProps} from '../Button';
import {TypographyProps} from '../Typography';
import {ViewProps} from '../View';

import {TagSizes} from './types';

export const getTagStyles = ({
  size,
  isRTL,
  hasCheckbox,
  hasCounter,
  hasIcon,
  closable,
}: {
  size: TagSizes;
  isRTL?: boolean;
  hasIcon?: boolean;
  hasCheckbox?: boolean;
  hasCounter?: boolean;
  closable?: boolean;
}): {
  container: ViewProps;
  text: TypographyProps;
  closeIcon?: {
    container?: ButtonProps;
    icon?: IconProps;
  };
  counter?: {
    container?: ViewProps;
    text?: TypographyProps;
  };
} => {
  const _sizesContainerStyles: {[size in TagSizes]: ViewProps} = {
    sm: {
      paddingVertical: '$spacing-xxs',
      paddingHorizontal: '$spacing-md',
      minHeight: 24,
    },
    md: {
      paddingVertical: '$spacing-xxs',
      paddingHorizontal: 9,
      minHeight: 24,
    },
    lg: {
      paddingVertical: '$spacing-xs',
      paddingHorizontal: 10,
      minHeight: 28,
    },
  };

  const _sizeTextStyle: {[size in TagSizes]: TypographyProps} = {
    sm: {
      fontSize: '$text-xs',
    },
    md: {
      fontSize: '$text-sm',
    },
    lg: {
      fontSize: '$text-sm',
    },
  };

  const _sizeCloseIconContainerStyle: {[size in TagSizes]: ButtonProps} = {
    sm: {
      padding: '$spacing-xxs',
      height: 14,
      width: 14,
    },
    md: {
      padding: '$spacing-xxs',
      height: 16,
      width: 16,
    },
    lg: {
      padding: 3,
      height: 20,
      width: 20,
    },
  };

  const _sizeCloseIconStyle: {[size in TagSizes]: IconProps} = {
    sm: {
      size: 10,
    },
    md: {
      size: 12,
    },
    lg: {
      size: 14,
    },
  };

  const _sizeCounterContainerStyle: {[size in TagSizes]: ViewProps} = {
    sm: {
      paddingHorizontal: '$spacing-xs',
      height: 16,
    },
    md: {
      height: 18,
      paddingHorizontal: 5,
    },
    lg: {
      height: 20,
      paddingHorizontal: '$spacing-sm',
    },
  };

  const _sizeCounterTextStyle: {[size in TagSizes]: TypographyProps} = {
    sm: {
      fontSize: '$text-xs',
    },
    md: {
      fontSize: '$text-xs',
    },
    lg: {
      fontSize: '$text-sm',
    },
  };

  let _containerPaddingStart: ViewProps = {};
  const paddingProperyInlineStart = isRTL ? 'paddingRight' : 'paddingLeft';

  if (hasIcon) {
    _containerPaddingStart = {
      sm: {
        [paddingProperyInlineStart]: '$spacing-xs',
      },
      md: {
        [paddingProperyInlineStart]: 5,
      },
      lg: {
        [paddingProperyInlineStart]: 7,
      },
    }[size];
  }

  if (hasCheckbox) {
    _containerPaddingStart = {
      sm: {
        [paddingProperyInlineStart]: 5,
      },
      md: {
        [paddingProperyInlineStart]: '$spacing-xs',
      },
      lg: {
        [paddingProperyInlineStart]: 5,
      },
    }[size];
  }

  let _containerPaddingEnd: ViewProps = {};
  const paddingProperyInlineEnd = isRTL ? 'paddingLeft' : 'paddingRight';

  if (hasCounter) {
    _containerPaddingEnd = {
      sm: {
        [paddingProperyInlineEnd]: '$spacing-xs',
      },
      md: {
        [paddingProperyInlineEnd]: 3,
      },
      lg: {
        [paddingProperyInlineEnd]: '$spacing-xs',
      },
    }[size];
  }

  if (closable) {
    _containerPaddingEnd = {
      [paddingProperyInlineEnd]: '$spacing-xs',
    };
  }

  return {
    container: {
      width: 'max-content',
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'solid',
      borderRadius: '$rounded-sm',
      borderColor: '$border-primary',
      backgroundColor: '$bg-primary',
      borderWidth: 1,
      gap: 3,
      ..._sizesContainerStyles[size],
      ..._containerPaddingStart,
      ..._containerPaddingEnd,
    },
    text: {
      fontWeight: '500',
      color: '$text-secondary',
      lineHeight: '$1',
      ..._sizeTextStyle[size],
    },
    closeIcon: {
      container: {..._sizeCloseIconContainerStyle[size], borderRadius: 3},
      icon: {..._sizeCloseIconStyle[size], color: '$fg-quinary'},
    },
    counter: {
      container: {
        borderRadius: 3,
        backgroundColor: '$bg-tertiary',
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        ..._sizeCounterContainerStyle[size],
      },
      text: {color: '$text-secondary', ..._sizeCounterTextStyle[size], lineHeight: 1},
    },
  };
};

// todo: add docs
import React, {useContext, ComponentProps} from 'react';

import {ErrorBoundary} from '../components/ErrorBoundary';

export const AppletContext = React.createContext<string>('');

export const useAppletContext = () => {
  return useContext(AppletContext);
};

export const withAppletContext = (Component, value: string) => (props: ComponentProps<typeof Component>) => {
  if (!Component) return null;

  return (
    <AppletContext.Provider value={value}>
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </AppletContext.Provider>
  );
};

import {useCallback, useMemo, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {getLocalizedText} from '../../LocalizedText';
import {SelectProps} from '../types';

export const useSelectSearch = ({
  searchValue: externalSearchValue,
  options,
  showSearch,
  onSearch: externalOnSearch,
}: SelectProps) => {
  const {t, i18n} = useTranslation();

  const [internalSearchValue, setInternalSearchValue] = useState('');
  const searchValue = externalSearchValue || internalSearchValue;

  const onSearch = (value: string) => {
    setInternalSearchValue(value);

    if (externalOnSearch) {
      externalOnSearch?.(value);
    }
  };

  const filterOptions = useCallback(
    (searchQuery: string, opts: typeof options) => {
      if (!searchQuery || !opts) return opts;

      return opts.filter((option) => {
        if (!option?.label) return false;

        const label = getLocalizedText({value: option?.label, t, i18n});

        return label.toLowerCase().includes(searchQuery.toLowerCase());
      });
    },
    [i18n.language],
  );

  const filteredOptions = useMemo(
    () => (showSearch ? filterOptions(searchValue, options) : options),
    [filterOptions, options, searchValue, showSearch],
  );

  return {
    filterOptions,
    filteredOptions,
    searchValue,
    onSearch,
  };
};

import {useCallback, useState} from 'react';

import {SelectProps} from '../types';

export const useSelectIsOpen = ({open, onOpenChange, onClose}: SelectProps & {onClose?: () => void}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isControlled = open !== undefined;
  const isOpen = isControlled ? open : internalIsOpen;

  const handleOpenChange = useCallback(
    (openState: boolean) => {
      if (!isControlled) {
        setInternalIsOpen(openState);
      }
      onOpenChange?.(openState);

      if (!openState) {
        onClose?.();
      }
    },
    [onOpenChange],
  );

  return {
    isOpen,
    onOpen: handleOpenChange,
  };
};

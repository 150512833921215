import React from 'react';

import {LocalizedTextType} from '../../../types';
import {Label, Subtext} from '../../Input';
import {View, ViewProps} from '../../View';
import {YStack} from '../../YStack';

type Props = {
  label?: React.ReactNode;
  subtext?: LocalizedTextType;
  children?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  containerProps?: ViewProps;
};

export const SelectWrapper: React.FC<Props> = ({label, subtext, required, children, error, containerProps}) => {
  return (
    <YStack {...containerProps}>
      {!!label && (
        <View>
          <Label label={label} required={required} />
        </View>
      )}
      {children}
      {!!subtext && (
        <View>
          <Subtext subtext={subtext} error={error} />
        </View>
      )}
    </YStack>
  );
};

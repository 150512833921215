import React from 'react';

import {ColorTokens} from 'tamagui';

import {useTokenColor} from '../../../utils';
import {SocialIconState, SvgSocialIconProps} from '../types';

export const GoogleIcon: React.FC<SvgSocialIconProps> = ({theme = 'brand', state = 'default', ...props}) => {
  const _WhiteColor = useTokenColor('$fg-white');

  if (theme === 'gray') {
    return <GoogleGrayTheme state={state} {...props} />;
  }

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1269_747)">
        <path
          d="M23.7663 12.2764C23.7663 11.4607 23.7001 10.6406 23.559 9.83807H12.2402V14.4591H18.722C18.453 15.9494 17.5888 17.2678 16.3233 18.1056V21.1039H20.1903C22.4611 19.0139 23.7663 15.9274 23.7663 12.2764Z"
          fill="#4285F4"
        />
        <path
          d="M12.2401 24.0008C15.4766 24.0008 18.2059 22.9382 20.1945 21.1039L16.3276 18.1055C15.2517 18.8375 13.8627 19.252 12.2445 19.252C9.11388 19.252 6.45946 17.1399 5.50705 14.3003H1.5166V17.3912C3.55371 21.4434 7.7029 24.0008 12.2401 24.0008Z"
          fill="#34A853"
        />
        <path
          d="M5.50277 14.3003C5.00011 12.8099 5.00011 11.1961 5.50277 9.70575V6.61481H1.51674C-0.185266 10.0056 -0.185266 14.0004 1.51674 17.3912L5.50277 14.3003Z"
          fill="#FBBC04"
        />
        <path
          d="M12.2401 4.74966C13.9509 4.7232 15.6044 5.36697 16.8434 6.54867L20.2695 3.12262C18.1001 1.0855 15.2208 -0.034466 12.2401 0.000808666C7.7029 0.000808666 3.55371 2.55822 1.5166 6.61481L5.50264 9.70575C6.45064 6.86173 9.10947 4.74966 12.2401 4.74966Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_1269_747">
          <rect width="24" height="24" fill={_WhiteColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

const GoogleGrayTheme: React.FC<SvgSocialIconProps> = ({state = 'default', ...props}) => {
  const _stateColors: {
    [state in SocialIconState]?: ColorTokens;
  } = {
    default: '$fg-quaternary',
    hover: '$fg-quaternary-hover',
  };

  const _stateColor = _stateColors?.[state];
  const _color = _stateColor ? useTokenColor(_stateColor) : '';
  const _WhiteColor = useTokenColor('$fg-white');

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1256_132897)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8426 6.54781C15.6036 5.36611 13.9501 4.72235 12.2392 4.74881C9.10863 4.74881 6.44981 6.86086 5.5018 9.70486V9.70493C4.99913 11.1953 4.99913 12.8091 5.50179 14.2994H5.50619C6.45861 17.139 9.11303 19.2511 12.2437 19.2511C13.8597 19.2511 15.247 18.8378 16.3223 18.1077V18.1047C17.5878 17.2669 18.452 15.9486 18.721 14.4582H12.2393V9.83722H23.558C23.6991 10.6397 23.7653 11.4599 23.7653 12.2756C23.7653 15.9254 22.4609 19.0112 20.1913 21.1012L20.1937 21.103C18.2051 22.9373 15.4757 24 12.2392 24C7.70204 24 3.55286 21.4426 1.51575 17.3904V17.3904C-0.186243 13.9996 -0.186239 10.0048 1.51576 6.61398H1.51579L1.51575 6.61395C3.55286 2.55737 7.70204 -4.58262e-05 12.2392 -4.58262e-05C15.22 -0.0353205 18.0992 1.08465 20.2686 3.12176L16.8426 6.54781Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1256_132897">
          <rect width="24" height="24" fill={_WhiteColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

import React from 'react';

import {Platform} from 'react-native';
import {ScrollView} from 'tamagui';

import {Popover} from '../Popover';
import {View} from '../View';

import {DropdownProps} from './types';

export * from './types';
export * from './DropdownItem';

export function Dropdown({children, trigger, contentProps, header, headerProps, ...props}: DropdownProps) {
  const containerBorderRadius = contentProps?.borderRadius || '$rounded-md';

  return (
    <View alignItems="center" justifyContent="center" display="flex">
      <Popover
        closeOnClick
        hideArrow
        trigger={trigger}
        contentProps={{
          padding: 0,
          width: 248,
          backgroundColor: '$bg-primary',
          borderColor: '$border-secondary',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: containerBorderRadius,
          flexGrow: 1,
          flexShrink: 0,
          marginTop: 8,
          ...contentProps,
        }}
        placement="bottom"
        {...props}>
        <View width="100%" overflow="hidden" borderRadius={containerBorderRadius}>
          <ScrollView
            style={{maxHeight: (Platform.OS === 'web' ? 300 : '80vh') as any}}
            contentContainerStyle={{flexGrow: 1}}
            nestedScrollEnabled={true}
            showsVerticalScrollIndicator={true}>
            {header && (
              <View
                paddingVertical="$spacing-lg"
                paddingHorizontal="$spacing-xl"
                borderBottomWidth={1}
                borderBottomColor="$border-secondary"
                {...headerProps}>
                {header}
              </View>
            )}
            {children}
          </ScrollView>
        </View>
      </Popover>
    </View>
  );
}

import React, {useEffect, useState} from 'react';

import {Option} from '../../../types';
import {Tag} from '../../Tag';
import {XStack} from '../../XStack';
import {MultiSelectProps} from '../types';

type Props = {
  selectedOptions?: Option[];
  onChangeSelection?: (value: string) => void;
  size?: MultiSelectProps['size'];
  triggerWidth?: number | null;
};

export const BadgesContainer = ({selectedOptions = [], triggerWidth, size, onChangeSelection}: Props) => {
  const [visibleOptions, setVisibleOptions] = useState<Option[]>([]);
  const [hiddenCount, setHiddenCount] = useState(0);

  const calculateVisibleOptions = () => {
    if (!triggerWidth || !selectedOptions.length) return;

    let availableWidth = triggerWidth;
    const newVisibleOptions: Option[] = [];
    let newHiddenCount = 0;

    for (const option of selectedOptions) {
      const extraPadding = 40;
      const badgeCharcterWidth = 10;

      const estimatedTagWidth = (option as any)?.label?.length * badgeCharcterWidth + extraPadding;
      if (availableWidth - estimatedTagWidth > 0) {
        newVisibleOptions.push(option);
        availableWidth -= estimatedTagWidth;
      } else {
        newHiddenCount = selectedOptions.length - newVisibleOptions.length;
        break;
      }
    }

    setVisibleOptions(newVisibleOptions);
    setHiddenCount(newHiddenCount);
  };

  useEffect(calculateVisibleOptions, [triggerWidth, selectedOptions]);

  return (
    <XStack
      gap="$spacing-xs"
      onPress={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}>
      {visibleOptions.map((option) => (
        <Tag
          key={option.value}
          label={option.label as any}
          closable
          size={size}
          onPress={() => onChangeSelection?.(option.value)}
          icon={option.prefix}
        />
      ))}
      {hiddenCount > 0 && <Tag label={`+${hiddenCount}`} size={size} closable={false} />}
    </XStack>
  );
};

import {SHADOW_COLOR} from './colors';

export const SHADOW_BASE = {
  'shadow-xs': {
    shadowOffset: {
      width: 1,
      height: 1,
    },
    shadowOpacity: 0.05,
    shadowColor: SHADOW_COLOR['shadow-xs'],
    shadowRadius: 1,
  },
  'shadow-sm': {
    shadowOffset: {
      width: 1,
      height: 3,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-sm-01'],
    shadowRadius: 3,
  },
  'shadow-md': {
    shadowOffset: {
      width: 4,
      height: 6,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-md-01'],
    shadowRadius: 6,
  },
  'shadow-lg': {
    shadowOffset: {
      width: 12,
      height: 16,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-lg-01'],
    shadowRadius: 16,
  },
  'shadow-xl': {
    shadowOffset: {
      width: 20,
      height: 24,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-xl-01'],
    shadowRadius: 24,
  },
  'shadow-2xl': {
    shadowOffset: {
      width: 24,
      height: 48,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-2xl-01'],
    shadowRadius: 48,
  },
  'shadow-3xl': {
    shadowOffset: {
      width: 32,
      height: 64,
    },
    shadowOpacity: 0.2,
    shadowColor: SHADOW_COLOR['shadow-3xl-01'],
    shadowRadius: 64,
  },
};

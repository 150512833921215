import {ReactElement} from 'react';

import {ApiConnector} from './ApiConnector';
import {AppletContext, AppletInitContext} from './AppletContext';
import {IconType} from './IconType';
import {LocalizedTextType} from './LocalizedTextType';

export interface AppletWidgetComponent {
  (props: any, context?: any): ReactElement<any, any> | null;
}

export type WidgetGeneratedProps = {
  localId: string;
  appletId: string;
  name: LocalizedTextType;
};

export type WidgetOmitGenerated<T> = Omit<T, keyof WidgetGeneratedProps>;

export type BaseWidget = WidgetGeneratedProps & {
  id: string;
  Component?: AppletWidgetComponent;
  Icon?: IconType;
  Suffix?: IconType;
  public?: boolean;
  permissions?: string[];
  localizations?: {[key: string]: any};
  category?: string | null;
  extra?: any;
};

export type AppletWidget = BaseWidget & {
  mainServiceId?: any;
  builderSettings?: any;
  builderSettingsDefaultProps?: any;
};

// todo: document
export type AppletServiceType = 'full' | 'mini' | 'inline' | 'group';

export type AppletService = BaseWidget & {
  hideNavigationHeader?: boolean;
  type?: AppletServiceType;
  unList?: boolean;
  subServices?: (AppletService | WidgetOmitGenerated<AppletService>)[][];
  parentServiceId?: string;
};

export type AppletConfig = BaseWidget;

export enum AppletUpdateReasons {
  INIT = 'INIT',
  UPDATE_CALENDAR = 'UPDATE_CALENDAR',
  UPDATE_USERINFO = 'UPDATE_USERINFO',
}

export interface AppletBase {
  id: string;
  category?: string;
  localizations: {[key: string]: any};
  init: (context: AppletInitContext, apiConnector?: ApiConnector) => Promise<any>;
  canView?: (widget: BaseWidget, context: AppletContext) => boolean;
  updateState?: (reason: AppletUpdateReasons, context: AppletContext) => Promise<any>;
  _context?: AppletContext;
}

export type Applet = AppletBase & {
  name: LocalizedTextType;
  services: AppletService[];
  widgets: AppletWidget[];
  configurations: AppletConfig[];
  userConfigurations: AppletConfig[];
};

export type AppletTemplate = AppletBase & {
  services: WidgetOmitGenerated<AppletService>[];
  widgets: WidgetOmitGenerated<AppletWidget>[];
  configurations: WidgetOmitGenerated<AppletConfig>[];
  userConfigurations: WidgetOmitGenerated<AppletConfig>[];
};

export type AppletDefinition = {
  id: string;
  load: () => Promise<{default: Applet}>;
};

import {addDays, eachDay, endOfMonth, endOfWeek, format, getDay, startOfMonth, startOfWeek} from '../utils/date';

type FirstDayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export interface GetWeekdayLabelsProps {
  firstDayOfWeek?: FirstDayOfWeek;
  weekdayLabelFormat?(date: Date): string;
}

export function getWeekdayLabels({
  firstDayOfWeek = 1,
  weekdayLabelFormat = (date: Date) => format(date, 'iiiiii'),
}: GetWeekdayLabelsProps = {}) {
  const now = new Date();
  const arr = eachDay({
    start: addDays(startOfWeek(now), firstDayOfWeek),
    end: addDays(endOfWeek(now), firstDayOfWeek),
  });
  return arr.reduce((array, date) => {
    array.push(weekdayLabelFormat(date) as never);
    return array;
  }, []);
}

export interface GetDaysProps {
  year: number;
  month: number;
  firstDayOfWeek?: FirstDayOfWeek;
  dayLabelFormat?(date: Date): string;
}

export type CalendarDay = number | {dayLabel: string; date: Date; isCurrentMonth: boolean};

const totalCalendarDays = 42;

export function getDays({
  year,
  month,
  firstDayOfWeek = 1,
  dayLabelFormat = (date: Date) => format(date, 'dd'),
}: GetDaysProps): CalendarDay[] {
  const date = new Date(year, month);

  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const monthStartDay = getDay(monthStart);

  const prevMonthDaysCount =
    monthStartDay >= firstDayOfWeek ? monthStartDay - firstDayOfWeek : 7 - (firstDayOfWeek - monthStartDay);

  const prevMonthDays = Array.from({length: prevMonthDaysCount}).map((_, index) => {
    const prevDate = addDays(monthStart, -(prevMonthDaysCount - index));
    return {
      date: prevDate,
      dayLabel: dayLabelFormat(prevDate),
      isCurrentMonth: false,
    };
  });

  const calendarStart = startOfWeek(monthStart, {weekStartsOn: firstDayOfWeek});
  let calendarEnd = endOfWeek(monthEnd, {weekStartsOn: firstDayOfWeek});

  const totalDays = eachDay({start: calendarStart, end: calendarEnd});
  if (totalDays.length < totalCalendarDays) {
    calendarEnd = addDays(calendarEnd, totalCalendarDays - totalDays.length);
  }

  const days = eachDay({start: monthStart, end: monthEnd}).map((date) => ({
    date,
    dayLabel: dayLabelFormat(date),
    isCurrentMonth: true,
  }));

  const nextMonthDaysCount = totalCalendarDays - (prevMonthDays.length + days.length);
  const nextMonthDays = Array.from({length: nextMonthDaysCount}).map((_, index) => {
    const nextDate = addDays(monthEnd, index + 1);
    return {
      date: nextDate,
      dayLabel: dayLabelFormat(nextDate),
      isCurrentMonth: false,
    };
  });

  return [...prevMonthDays, ...days, ...nextMonthDays];
}

import React from 'react';

import {Dialog as BaseDialog} from 'tamagui';
import {ScrollView} from 'tamagui';

import {Button} from '../../Button';
import {IconView} from '../../IconView';
import {Pressable} from '../../Pressable';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {YStack} from '../../YStack';
import {SlideoutMenuProps} from '../types';

type SlideoutMenuContentProps = Pick<
  SlideoutMenuProps,
  | 'title'
  | 'contentProps'
  | 'content'
  | 'hideFooter'
  | 'onCancel'
  | 'onAccept'
  | 'onClose'
  | 'header'
  | 'footer'
  | 'confirmText'
  | 'cancelText'
>;

export const SlideoutMenuContent = ({
  title,
  header,
  footer,
  contentProps,
  content,
  hideFooter,
  onCancel,
  onAccept,
  onClose,
  confirmText,
  cancelText,
}: SlideoutMenuContentProps) => {
  return (
    <>
      {header ||
        (title && (
          <BaseDialog.Title
            flex={1}
            paddingHorizontal="$4"
            paddingVertical="$6"
            position="absolute"
            width="100%"
            fontSize="$6">
            <XStack alignItems="center" justifyContent="space-between">
              <Typography color="$alpha-black-100">{title}</Typography>
              <BaseDialog.Close asChild onPress={onClose}>
                <Pressable>
                  <IconView color="$fg-quaternary" size={16} icon="Cancel01Icon" />
                </Pressable>
              </BaseDialog.Close>
            </XStack>
          </BaseDialog.Title>
        ))}
      <ScrollView flexGrow={1} showsVerticalScrollIndicator marginTop={title ? '$13' : 0}>
        <YStack justifyContent="space-between" $gtMd={{marginVertical: '$4'}} minHeight="$14" {...contentProps}>
          {content}
        </YStack>
      </ScrollView>
      {!hideFooter && (
        <XStack
          margin="$0.5"
          paddingHorizontal="$4"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          marginTop="$4"
          flexDirection="row-reverse"
          borderTopWidth={1}
          borderColor="$border-secondary">
          <XStack gap="$3" alignItems="center" paddingVertical="$4">
            {onCancel && (
              <Button onPress={onCancel} hierarchy="secondary-gray">
                {cancelText || <CoreTrans i18nKey="action.cancel" />}
              </Button>
            )}
            {onAccept && (
              <Button onPress={onAccept} hierarchy="primary">
                {confirmText || <CoreTrans i18nKey="action.confirm" />}
              </Button>
            )}
          </XStack>

          {footer}
        </XStack>
      )}
    </>
  );
};

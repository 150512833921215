import React from 'react';

import {YStack} from 'tamagui';

import {getLocalizedText} from '../../LocalizedText';
import {NoDataMessage} from '../../NoDataMessage';
import {Popover} from '../../Popover';
import {SelectOption, SelectWrapper, Trigger} from '../../Select';
import {useSelect} from '../hooks/useSelect';
import {MultiSelectProps} from '../types';

import {BadgesContainer} from './BadgesContainer';

export const WebSelect = ({
  options,
  value,
  onChange,
  placeholder,
  unstyled,
  children,
  subtext,
  error,
  required,
  prefix,
  suffix,
  disabled,
  label,
  open,
  onOpenChange,
  showSearch = false,
  loading,
  size,
  searchValue: externalSearchValue,
  onSearch: externalOnSearch,
  ...containerProps
}: MultiSelectProps) => {
  const {
    isOpen,
    handleOpenChange,
    searchValue,
    filteredOptions,
    inputRef,
    onSearch,
    t,
    i18n,
    selectedOptions,
    triggerWidth,
    setTriggerWidth,
    isSelected,
    onChangeSelection,
    onTriggerKeyPress,
  } = useSelect({
    open,
    value,
    searchValue: externalSearchValue,
    options,
    showSearch,
    onOpenChange,
    onChange,
    onSearch: externalOnSearch,
  });

  return (
    <SelectWrapper label={label} subtext={subtext} error={error} required={required} containerProps={containerProps}>
      <Popover
        hideArrow
        onOpenChange={handleOpenChange}
        triggerProps={{
          width: '100%',
        }}
        closable
        open={isOpen}
        contentProps={{marginVertical: 5, padding: 0, borderRadius: '$rounded-lg', overflow: 'hidden', id: 'test'}}
        trigger={
          <Trigger
            inputRef={inputRef}
            placeholder={!!selectedOptions?.length ? undefined : getLocalizedText({value: placeholder, t, i18n})}
            disabled={disabled}
            showSearch={showSearch}
            onKeyPress={onTriggerKeyPress}
            prefix={
              selectedOptions?.length ? (
                <BadgesContainer
                  triggerWidth={triggerWidth}
                  selectedOptions={selectedOptions}
                  onChangeSelection={onChangeSelection}
                />
              ) : (
                prefix
              )
            }
            error={error}
            value={isOpen && showSearch ? searchValue : ''}
            onSearch={onSearch}
            suffix={suffix}
            unstyled={unstyled}
            loading={loading}
            size={size}
            onSetTriggerWidth={setTriggerWidth}
          />
        }>
        <YStack
          borderRadius="$rounded-md"
          backgroundColor="$bg-primary"
          borderStyle="solid"
          borderWidth={1}
          borderColor="$border-secondary"
          hoverStyle={null}
          paddingVertical="$spacing-xs"
          paddingHorizontal="$spacing-sm"
          width={triggerWidth}
          maxHeight={300}
          overflow="scroll">
          {!filteredOptions?.length && !children && <NoDataMessage hasNoData hideRipples />}
          {filteredOptions?.map((option, i) => (
            <SelectOption
              key={`${option.value}-${i}`}
              option={option}
              isSelected={isSelected(option.value)}
              onChangeSelection={() => {
                onChangeSelection(option.value);
                onSearch('');
              }}
            />
          ))}
          {children}
        </YStack>
      </Popover>
    </SelectWrapper>
  );
};

import React from 'react';

import {View, ViewProps} from '../../View';
import {SortedBy, TableProps} from '../model';

import {ColumnHeader} from './ColumnHeader';
import {Row} from './Row';
import {RowSelector} from './RowSelector';

type Props<T> = {
  onSelect?: TableProps<T>['onSelect'];
  showIndices?: TableProps<T>['showIndices'];
  columns: TableProps<T>['columns'];
  sortBy: TableProps<T>['sortBy'];
  selectAll: boolean;
  sortedBy: React.MutableRefObject<SortedBy<T>>;
  columnHeaderProps?: ViewProps;
  defaultSort: (sortBy: SortedBy<T>) => void;
  onSort: TableProps<T>['onSort'];
  extendedView?: TableProps<T>['extendedView'];
  onToggleSelectAll: () => void;
} & ViewProps;

export function RowHeader<T>({
  columns,
  onSelect,
  sortBy,
  showIndices,
  selectAll,
  sortedBy,
  columnHeaderProps,
  defaultSort,
  onSort,
  extendedView,
  onToggleSelectAll,
  ...props
}: Props<T>) {
  return (
    <Row backgroundColor="$bg-secondary" {...props}>
      {extendedView && <View width="$4" />}
      {onSelect && <RowSelector selected={selectAll} onSelectToggle={onToggleSelectAll} />}
      {showIndices && (
        <ColumnHeader flex={0} width="$3">
          #
        </ColumnHeader>
      )}

      {columns?.map((column) => (
        <ColumnHeader
          sortable={sortBy?.includes(column.field)}
          sortBy={sortedBy.current.get(column.field)}
          key={column.field as string}
          width={column.width}
          onSort={(direction) => {
            if (direction) {
              sortedBy.current.set(column.field, direction);
            } else {
              sortedBy.current.delete(column.field);
            }

            onSort?.(sortedBy.current) || defaultSort(sortedBy.current);
          }}
          {...columnHeaderProps}>
          {column.label}
        </ColumnHeader>
      ))}
    </Row>
  );
}

import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {coreScopedKey} from '../../../../../../utils';
import {Button} from '../../../../../Button';
import {Dialog} from '../../../../../Dialog';
import {IconView} from '../../../../../IconView';
import {Input} from '../../../../../Input';
import {Modal} from '../../../../../Modal';
import {CoreTrans} from '../../../../../ScopedTrans';
import {Column} from '../../../../../Table';
import {Text} from '../../../../../Text';
import {Typography} from '../../../../../Typography';
import {View} from '../../../../../View';
import {XStack} from '../../../../../XStack';
import {YStack} from '../../../../../YStack';
import {EditSectionElement} from '../../../../components';

export type Props = {
  columns: Column<any>[];
  onChange: (key: string, value: unknown) => void;
};

export const TableColumns = ({columns, onChange}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [column, setColumn] = useState<Column<any> | null>(null);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {t} = useTranslation();

  const onUpdateProps = (key: string, value: unknown) => {
    onChange(key, value);
  };
  const handleEditColumn = () => {
    const newColumns = columns.toSpliced(selectedColumnIndex as number, 1, column as Column<any>);
    onUpdate(newColumns);
    setIsEdit(false);
  };
  const handleAddNewColumn = () => {
    const newColumns = [
      ...columns,
      {
        label: column?.label,
        field: column?.field,
      },
    ];
    onUpdate(newColumns);
  };
  const onUpdate = (columns) => {
    onUpdateProps('columns', columns);
    setOpenModal(false);
    setSelectedColumnIndex(null);
    setColumn(null);
  };
  const handleDeleteColumn = (field: string) => {
    const newColumns = columns.filter((c) => c.field !== field);
    onUpdateProps('columns', newColumns);
  };

  return (
    <YStack>
      <EditSectionElement
        title={<CoreTrans i18nKey="table.columns" />}
        action={
          <Button
            prefix={<IconView icon="PlusSignCircleIcon" />}
            hierarchy="tertiary-gray"
            height="auto"
            onPress={() => setOpenModal(true)}>
            <CoreTrans i18nKey="action.add" />
          </Button>
        }>
        {!!columns.length && (
          <YStack
            borderWidth={1}
            borderColor="$border"
            maxHeight="$15"
            padding="$2"
            gap="$2"
            borderRadius="$8"
            overflow="scroll">
            {columns.map((column, index) => (
              <YStack key={index}>
                <XStack
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="$8"
                  padding="$0.5"
                  paddingHorizontal="$2"
                  borderWidth={1}
                  borderColor="$border">
                  <View>
                    <Typography fontSize="$16">{column.label}</Typography>
                  </View>
                  <XStack alignItems="center" gap="$1">
                    <Button
                      prefix={<IconView icon="PencilEdit02Icon" />}
                      hierarchy="tertiary-gray"
                      onPress={() => {
                        setColumn({...column});
                        setOpenModal(true);
                        setIsEdit(true);
                        setSelectedColumnIndex(index);
                      }}
                    />

                    <Modal
                      title={t(coreScopedKey('action.delete'))}
                      trigger={
                        <Button
                          prefix={<IconView icon="Delete01Icon" color="$error-400" />}
                          hierarchy="tertiary-gray"
                        />
                      }
                      onAccept={() => handleDeleteColumn(column.field as string)}
                      type="danger"
                      content={t('builder.deleteStateMsg')}
                    />
                  </XStack>
                </XStack>
              </YStack>
            ))}
          </YStack>
        )}
        <Dialog
          open={openModal}
          key={column?.field as string}
          onClose={() => {
            setOpenModal(false);
            setIsEdit(false);
            setColumn(null);
          }}
          title={`${isEdit ? t('action.edit') : t('action.add')} Column`}
          sideBarMode={false}
          content={
            <YStack gap={10} paddingHorizontal="$4">
              <Input
                onChangeText={(value) => {
                  setColumn({
                    label: <Text>{value}</Text>,
                    field: value,
                  });
                }}
                value={column?.field as string}
                label={<CoreTrans i18nKey="table.columnLabel" />}
              />
              <Button
                width="$7"
                alignSelf="flex-end"
                onPress={isEdit ? handleEditColumn : handleAddNewColumn}
                suffix={<IconView icon="PlusSignCircleIcon" />}>
                <CoreTrans i18nKey={isEdit ? 'action.edit' : 'action.add'} />
              </Button>
            </YStack>
          }
        />
      </EditSectionElement>
    </YStack>
  );
};

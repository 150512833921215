import React, {useState} from 'react';

import {ListCardItem} from '../ListCardItem';
import {Radio, RadioContainer} from '../Radio';
import {View} from '../View';
import {YStack} from '../YStack';

import {RadioGroupProps} from './types';

export * from './types';

export function RadioGroup({
  defaultValue = '',
  value,
  items,
  onChange,
  size,
  disabled = false,
  containerProps,
  ...props
}: RadioGroupProps) {
  const [selected, setSelected] = useState(value ?? defaultValue);

  const onItemCheckedChange = (val: string) => {
    if (selected !== val) {
      setSelected(val);
      onChange?.(val);
    }
  };

  return (
    <RadioContainer onValueChange={onItemCheckedChange} value={selected} disabled={disabled} {...props}>
      <YStack gap="$spacing-xl" {...containerProps}>
        {items?.map((item, index) => {
          const {title, subTitle, description, prefix, value, disabled: itemDisabled, ...rest} = item;
          const checked = selected === value;
          const key = `${value}-${index}`;

          const renderRadioButton = (
            <Radio value={value} size={size} checked={checked} disabled={disabled || itemDisabled} {...rest} />
          );

          return (
            <View key={key}>
              <ListCardItem
                title={title}
                subTitle={subTitle}
                description={description}
                prefix={prefix ? prefix : renderRadioButton}
                suffix={!!prefix ? renderRadioButton : null}
                size={size}
                checked={checked}
                disabled={disabled || itemDisabled}
              />
            </View>
          );
        })}
      </YStack>
    </RadioContainer>
  );
}

import {useFlags} from '@featbit/react-client-sdk';

/**
 * A custom hook that provides access to feature flags.
 *
 * This hook uses the `useFlags` hook from `@featbit/react-client-sdk`
 * to retrieve and manage feature flags within the application.
 *
 * @returns A proxy object containing the current state of feature flags.
 * The returned object remains reactive, reflecting real-time flag updates.
 */
export const useFeatureFlags: () => Record<string, any> = () => {
  return useFlags();
};

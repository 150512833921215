import React from 'react';

import {HugeiconsIcon} from '@hugeicons/react-native';

import {HugeIcons, HugeIconsDefault, HugeIconListType} from '@medad-sep/hugeicons';

import {IconProps} from '../../types';

export {IconsList} from './IconsList';

export type IconListType = HugeIconListType;

type Props = IconProps & {
  name: IconListType;
  fill?: string;
};

export const Icon = ({name, size = 20, variant = 'stroke', ...props}: Props) => {
  if (name && !HugeIconsDefault[name]) {
    name = 'HexagonIcon';
  }

  const icon = HugeIcons[variant][name] as any;

  if (!icon) return null;

  return <HugeiconsIcon icon={icon} size={size} {...props} />;
};

import {useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native';

import {useSelectIsOpen, useSelectSearch} from '../../Select';
import {MultiSelectProps} from '../types';

import {useSelectValues} from './useSelectedValues';

export const useSelect = ({
  open,
  value,
  searchValue: externalSearchValue,
  options,
  showSearch,
  onOpenChange,
  onChange,
  onSearch: externalOnSearch,
}: MultiSelectProps) => {
  const [triggerWidth, setTriggerWidth] = useState<number | null>(null);

  const {t, i18n} = useTranslation();
  const inputRef = useRef<any>();

  const {selectedOptions, isSelected, onChangeSelection, onReset} = useSelectValues({value, onChange, options});
  const {filterOptions, filteredOptions, searchValue, onSearch} = useSelectSearch({
    searchValue: externalSearchValue,
    options,
    showSearch,
    onSearch: externalOnSearch,
  });

  const {isOpen, onOpen} = useSelectIsOpen({
    open,
    onOpenChange,
    onClose: () => {
      onSearch?.('');
      inputRef?.current?.blur();
    },
  });

  const onTriggerKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (!selectedOptions?.length || searchValue) return;

    const {key} = event.nativeEvent;
    if (key === 'Backspace' && selectedOptions?.length > 0) {
      const lastOption = selectedOptions[selectedOptions.length - 1];
      onChangeSelection?.(lastOption.value);
    }
  };

  return {
    filterOptions,
    isOpen,
    handleOpenChange: onOpen,
    filteredOptions,
    searchValue,
    onSearch,
    inputRef,
    t,
    i18n,
    triggerWidth,
    setTriggerWidth,
    selectedOptions,
    isSelected,
    onChangeSelection,
    onReset,
    onTriggerKeyPress,
  };
};

import {useState, useEffect, RefObject} from 'react';

import {Platform} from 'react-native';
// TODO : recheck this when we upgrade tamagui package version
export const useDynamicPlacement = (triggerRef: RefObject<any>, isOpen: boolean, contentHeight: number) => {
  const [placement, setPlacement] = useState<'top' | 'bottom' | 'right-start' | 'left-start'>('bottom');

  useEffect(() => {
    if (Platform.OS !== 'web') return;
    if (!isOpen) return;

    if (triggerRef.current && triggerRef.current.measure) {
      triggerRef.current.measure((_fx, _fy, width, height, px, py) => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        const spaceBelow = screenHeight - (py + height);
        const spaceAbove = py;
        const spaceRight = screenWidth - (px + width);
        const spaceLeft = px;

        if (spaceBelow >= contentHeight) {
          setPlacement('bottom');
        } else if (spaceAbove >= contentHeight) {
          setPlacement('top');
        } else if (spaceRight > spaceLeft) {
          setPlacement('right-start');
        } else {
          setPlacement('left-start');
        }
      });
    }
  }, [isOpen, contentHeight]);

  return placement;
};

import React, {useEffect, useState} from 'react';

import {Trans} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../../utils';
import {ColorInput} from '../../../../ColorInput';
import {ProgressBarProps} from '../../../../ProgressBar';
import {CoreTrans} from '../../../../ScopedTrans';
import {useStateContext} from '../../../../StateProvider';
import {EditSectionElement, StateAwareInput} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {useStateResolver} from '../../../hooks/useStateResolver';

export const _ProgressBarBuilder: PropsBuilder<ProgressBarProps> = ({
  value: propsValue,
  onChange,
  onReset,
  options,
}) => {
  const {color, backgroundColor, value} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };
  const [inputValue, setInputValue] = useState(`${value || 0}`);
  const {resolve} = useStateResolver();
  useEffect(() => {
    const resolvedValue = resolve(inputValue);
    if (resolvedValue) {
      const numericValue = Number(resolvedValue);
      if (!isNaN(numericValue)) {
        return onUpdateProps('value', Math.min(Math.max(0, numericValue), 100));
      }
      onUpdateProps('value', 0);
    }
  }, [inputValue]);
  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };
  const context = useStateContext();
  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="progress.value" />} onReset={() => onResetProps(['value'])}>
        <StateAwareInput
          types={['number']}
          value={`${inputValue}`}
          onChangeText={(value) => {
            setInputValue(value);
          }}
          options={options}
          onStateSelected={(value: {scopeId: string; stateId: string}) => {
            const state = context?.state[value.scopeId]?.find((state) => state.id === value.stateId);
            setInputValue(`${state?.value} ` || '');
          }}
        />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.style.color" />} onReset={() => onResetProps(['color'])}>
        <ColorInput value={color as any} onChange={(value) => onUpdateProps('color', value)} />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onResetProps(['backgroundColor'])}>
        <ColorInput
          value={(backgroundColor as any) || 'black'}
          onChange={(value) => onUpdateProps('backgroundColor', value)}
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const ProgressBarBuilder: BuilderPropsGroup<ProgressBarProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.ProgressElement'),
      Content: _ProgressBarBuilder,
    },
  ],
};

import React, {forwardRef} from 'react';

import {View} from 'react-native';
import {ColorTokens, getToken, useTheme} from 'tamagui';

import {useAppConfig} from '../../context';
import {Icon, IconListType} from '../../lib';
import {IconProps, IconType} from '../../types';
import {isReactComponent} from '../../utils';

type Props = IconProps & {
  icon?: IconType;
  defaultIcon?: IconListType;
  fill?: boolean;
  fillColor?: ColorTokens;
  brand?: boolean;
  color?: ColorTokens;
};

export const _IconView = (
  {
    icon,
    defaultIcon,
    color = '$text-primary',
    brand,
    fill,
    fillColor = '$transparent',
    variant = 'stroke',
    ...props
  }: Props,
  ref,
) => {
  const appConfig = useAppConfig();
  const theme = useTheme();

  const filledColor = fillColor == '$brand-700' ? appConfig.brandColor : theme[fillColor]?.val ?? 'transparent';

  let iconColor = getToken(color as any) || (color && theme[color]?.val) || color;

  if (brand || iconColor == '$brand-700') {
    iconColor = appConfig?.brandColor as any;
  }

  if (!iconColor) {
    iconColor = theme['$gray-600']?.val;
  }

  let content: any = null;

  if (!icon || typeof icon === 'string') {
    content = (
      <View ref={ref} {...props}>
        <Icon
          name={(icon || defaultIcon || 'HexagonIcon') as any}
          size={props.size}
          variant={variant}
          color={iconColor}
          fill={filledColor}
          {...props}
        />
      </View>
    );
  }

  if (isReactComponent(icon)) {
    const IconComponent = icon as any;
    content = <IconComponent {...props} color={iconColor} ref={ref} />;
  }

  if (content && fill) {
    content = (
      <View ref={ref} style={{height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        {content}
      </View>
    );
  }

  return content;
};

export const IconView = forwardRef(_IconView);

import React, {forwardRef, useState, ReactNode} from 'react';

import {LinearGradient} from '@tamagui/linear-gradient';
import {ImageURISource, ImageProps} from 'react-native';
import {Card as BaseCard, CardProps as BaseCardProps, GetRef, Image} from 'tamagui';

import {Typography} from '../Typography';

export type CardProps = Omit<BaseCardProps, 'content'> & {
  header?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  backgroundImageProps?: ImageProps;
  backgroundImageSource?: ImageURISource;
  capturePointerEvents?: boolean;
  ref?: React.Ref<GetRef<typeof BaseCard>>;
};

const INITIAL_GRADIENT_COLORS = ['black', 'gray'];
const HOVER_GRADIENT_COLORS = ['$brand-700', 'gray', 'gray'];

const _Card = ({
  header,
  content,
  footer,
  backgroundImageSource,
  backgroundImageProps,
  capturePointerEvents = true,
  ref,
  ...props
}: CardProps) => {
  const [gradientColor, setGradientColor] = useState(INITIAL_GRADIENT_COLORS);

  return (
    <BaseCard
      theme="translucent"
      elevate
      margin="$0.5"
      size="$1"
      borderWidth={0}
      width="$20"
      borderRadius="$rounded-lg"
      backgroundColor="$background"
      ref={ref}
      {...props}
      position="relative">
      <BaseCard.Header zIndex={2} pointerEvents={capturePointerEvents ? 'none' : undefined}>
        {typeof header === 'string' ? <Typography>{header}</Typography> : header}
        {typeof content === 'string' ? <Typography>{content}</Typography> : content}
      </BaseCard.Header>
      <BaseCard.Footer zIndex={2} padded pointerEvents={capturePointerEvents ? 'none' : undefined}>
        {typeof footer === 'string' ? <Typography>{footer}</Typography> : footer}
      </BaseCard.Footer>
      <LinearGradient
        borderRadius="$rounded-lg"
        width="100%"
        height="100%"
        zIndex={1}
        position="absolute"
        top={0}
        colors={gradientColor}
        start={[0, 4]}
        end={[0, 0]}
        opacity={0.5}
        onHoverIn={() => setGradientColor(HOVER_GRADIENT_COLORS)}
        onHoverOut={() => setGradientColor(INITIAL_GRADIENT_COLORS)}
        hoverStyle={{cursor: 'pointer'}}
      />
      <BaseCard.Background>
        <Image
          borderRadius="$rounded-lg"
          width="100%"
          height="100%"
          resizeMode="stretch"
          alignSelf="center"
          source={{
            uri: backgroundImageSource?.uri,
            ...backgroundImageSource,
          }}
          {...backgroundImageProps}
        />
      </BaseCard.Background>
    </BaseCard>
  );
};

export const Card = forwardRef(_Card);

import React, {ReactNode} from 'react';

import {Checkbox as BaseCheckbox, CheckboxProps as BaseCheckboxProps, Text} from 'tamagui';

import {IconProps} from '../../types';
import {IconView} from '../IconView';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {StateTransition, useCheckboxState} from './useCheckboxState';

export type CheckboxSizes = 'sm' | 'md' | 'lg';

export type CheckboxProps = BaseCheckboxProps & {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
  indeterminate?: boolean;
  size?: CheckboxSizes;
  stateTransition?: StateTransition;
  text?: ReactNode;
  supportText?: ReactNode;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  indeterminate = false,
  onCheckedChange,
  stateTransition = 'checkedUnchecked',
  disabled = false,
  text,
  supportText,
  size = 'md',
  ...props
}) => {
  const {state, handlePress: originalHandlePress} = useCheckboxState(checked, indeterminate, stateTransition);

  const handleCheckedChange = () => {
    originalHandlePress();
    if (onCheckedChange) {
      onCheckedChange(state === 'unchecked' ? true : false);
    }
  };
  const icon = state === 'checked' ? 'Tick02Icon' : state === 'indeterminate' ? 'MinusSignIcon' : null;
  const color = disabled ? '$gray-300' : state === 'unchecked' ? '$brand-600' : '$alpha-white-100';
  const backgroundColor = state === 'checked' || state === 'indeterminate' ? '$brand-600' : '$alpha-white-100';
  const disabledBackgroundColor = disabled ? '$gray-50' : backgroundColor;
  const sizesStyle: {[size in CheckboxSizes]: CheckboxProps} = {
    sm: {
      width: 14,
      height: 14,
    },
    md: {
      width: 16,
      height: 16,
    },
    lg: {
      width: 18,
      height: 18,
    },
  };

  const iconStyle: {[size in CheckboxSizes]: IconProps} = {
    sm: {
      size: 12,
    },
    md: {
      size: 14,
    },
    lg: {
      size: 16,
    },
  };

  return (
    <XStack gap="$spacing-md">
      <BaseCheckbox
        {...props}
        backgroundColor={disabled ? disabledBackgroundColor : backgroundColor}
        {...sizesStyle[size]}
        borderRadius="$rounded-xs"
        focusStyle={{outlineColor: '$brand-700', outlineWidth: 2, outlineStyle: 'solid'}}
        disabled={disabled}
        onCheckedChange={() => handleCheckedChange()}>
        {icon && <IconView color={color} icon={icon} {...iconStyle[size]} />}
      </BaseCheckbox>

      {(text || supportText) && (
        <YStack gap="$spacing-sm">
          {text && (
            <Text fontSize="$text-sm" lineHeight="$1" fontWeight="500" color="$text-secondary">
              {text}
            </Text>
          )}
          {supportText && (
            <Text fontSize="$text-sm" fontWeight="400" lineHeight="$1" color="$text-tertiary">
              {supportText}
            </Text>
          )}
        </YStack>
      )}
    </XStack>
  );
};

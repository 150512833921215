import React from 'react';

import {Typography, TypographyProps} from '../Typography';

import {InputProps} from './types';

type LabelProps = {
  label?: InputProps['label'];
  required?: boolean;
};

export const Label: React.FC<LabelProps> = ({label, required = false}) => {
  const _styles: TypographyProps = {
    color: '$text-secondary',
    fontWeight: '500',
    fontSize: '$text-sm',
    marginBottom: '$spacing-sm',
    display: 'flex',
  };

  if (!label) return null;

  return (
    <Typography {..._styles}>
      {label}
      {required && (
        <Typography {..._styles} marginBottom={0} color="$text-brand-tertiary">
          *
        </Typography>
      )}
    </Typography>
  );
};

import React from 'react';

import {useTranslation} from 'react-i18next';
import {NativeSyntheticEvent, TextInputKeyPressEventData} from 'react-native';

import {Input} from '../../Input';
import {getLocalizedText} from '../../LocalizedText';
import {Spinner} from '../../Spinner';
import {XStack} from '../../XStack';
import {SelectProps} from '../types';

import {SelectIcon} from './SelectIcon';

export type TriggerProps = SelectProps & {
  inputRef?: any;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onSetTriggerWidth?: (width: number) => void;
};

export function Trigger({
  unstyled,
  placeholder,
  value,
  inputRef,
  prefix,
  disabled,
  showSearch,
  onSetTriggerWidth,
  onSearch,
  error,
  loading,
  suffix,
  onKeyPress,
}: TriggerProps) {
  const {t, i18n} = useTranslation();

  const renderPrefix = () => {
    if (showSearch) {
      return (
        <XStack gap="$spacing-sm" alignItems="center">
          <SelectIcon icon="SearchIcon" disabled={disabled} />
          {prefix}
        </XStack>
      );
    }

    return prefix;
  };

  const renderSuffix = () => {
    if (loading) {
      return <Spinner color={disabled ? '$fg-disabled' : '$fg-quaternary'} size="small" />;
    }

    if (suffix) return suffix;

    return <SelectIcon icon="ArrowDown01Icon" disabled={disabled} />;
  };

  return (
    <Input
      containerProps={{
        width: '100%',
        position: 'relative',
        zIndex: 3000000000000,
        onLayout: (e) => {
          onSetTriggerWidth?.(e.nativeEvent.layout.width);
        },
      }}
      onKeyPress={onKeyPress}
      ref={inputRef}
      placeholder={getLocalizedText({value: placeholder, t, i18n})}
      disabled={disabled}
      editable={showSearch}
      prefix={renderPrefix()}
      error={error}
      value={value}
      onChangeText={onSearch}
      suffix={renderSuffix()}
      unstyled={unstyled}
    />
  );
}

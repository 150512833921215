import {Applet, AppletService} from '../../types';

export function getServicePageDefaults({appletId, serviceId}, applets: Applet[]) {
  const applet = applets.find((it) => it.id === appletId);

  for (const service of applet?.services || []) {
    if (service.id === serviceId) {
      return service;
    }

    for (const group of service.subServices || []) {
      for (const subService of group) {
        if (subService.id === serviceId) {
          return subService as AppletService;
        }
      }
    }
  }
}

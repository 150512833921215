import React, {ReactNode, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {coreScopedKey} from '../../../utils';
import {IconView} from '../../IconView';
import {InputProps} from '../../Input';
import {Input} from '../../Input';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {XStack} from '../../XStack';
import {ColumnsSelector} from '../hooks/useColumnsSelector';
import {TableProps} from '../model';

import {VisibleColumnsSelector} from './RowSelector/VisibleColumnsSelector';

interface Props<T> {
  title: ReactNode;
  columns: TableProps<T>['columns'];
  columnsSelector: ColumnsSelector<T>;
  filterable?: number | ((value: string) => void);
  onFilter?: (value: string) => void;
  defaultFilter?: (value: string) => void;
  filterInputProps?: InputProps;
}

export function TableHeader<T>({
  title,
  filterable,
  columns,
  columnsSelector,
  defaultFilter,
  filterInputProps,
}: Props<T>) {
  const [searchValue, setSearchValue] = useState<string>('');
  const {t} = useTranslation();

  const onFilterChange = (value: string) => {
    setSearchValue(value);
    if (defaultFilter) {
      defaultFilter(value);
    }
  };

  return (
    <XStack
      theme="translucent"
      gap="$4"
      justifyContent="space-between"
      backgroundColor="$bg-primary"
      paddingVertical="$3"
      paddingHorizontal="$6"
      $xs={{
        [filterable ? 'height' : 'maxHeight']: 128,
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingHorizontal: '$3.5',
        paddingVertical: '$8',
        gap: '$6',
      }}>
      <XStack justifyContent="flex-start" alignItems="center" flex={1} width="100%">
        <Typography fontSize="$text-lg" fontWeight="600">
          {title}
        </Typography>
      </XStack>
      {filterable && (
        <XStack gap="$2" width="40%" $xs={{width: '100%', flex: 1}}>
          <View>
            <VisibleColumnsSelector columns={columns} columnsSelector={columnsSelector} />
          </View>
          <View flexGrow={1}>
            <Input
              prefix={<IconView icon="Search01Icon" />}
              placeholder={t(coreScopedKey('action.search'))}
              value={searchValue}
              onChangeText={onFilterChange}
              {...filterInputProps}
            />
          </View>
        </XStack>
      )}
    </XStack>
  );
}

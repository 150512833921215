import {BadgeSizes, BadgeType} from '../../Badge';
import {BadgeGroupSizes, BadgeGroupTheme} from '../types';

export const getBadgetypeAccordingToTheme = (theme: BadgeGroupTheme): BadgeType => {
  const _themeType: {[key in BadgeGroupTheme]: BadgeType} = {
    light: 'pill-color',
    outline: 'pill-outline',
    modern: 'badge-modern',
  };
  return _themeType[theme];
};

export const getBadgeSizeAccordingToGroupSize = (size: BadgeGroupSizes): BadgeSizes => {
  const _sizes: {[key in BadgeGroupSizes]: BadgeSizes} = {
    lg: 'md',
    md: 'sm',
  };

  return _sizes[size];
};

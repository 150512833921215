import React from 'react';

import {IconType} from '../../../types';
import {IconView} from '../../IconView';

type Props = {
  icon: IconType;
  disabled?: boolean;
};

export const SelectIcon: React.FC<Props> = ({icon, disabled}) => {
  return <IconView size={15} icon={icon} color={disabled ? '$fg-disabled' : '$fg-quaternary'} />;
};

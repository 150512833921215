import React from 'react';

import {Trans} from 'react-i18next';

import {useAppImage} from '../../data';
import {EnvironmentProfile} from '../../types';
import {fileIsImage} from '../../utils';
import {Button} from '../Button';
import {IconView} from '../IconView';
import {UploadButton} from '../UploadButton';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

interface Props {
  value?: File | string;
  onChange?: (file: File | undefined) => void;
  disabled?: boolean;
  loading?: boolean;
  showPreview?: boolean;
  showRemoveButton?: boolean;
  showRemoveLabel?: boolean;
  serviceId?: string;
  useServiceImage?: (serviceId: string) => void;
  imageStyle?: React.CSSProperties;
}

export function UploadImage({
  value,
  onChange,
  disabled,
  loading,
  showPreview = true,
  showRemoveButton = true,
  showRemoveLabel = true,
  serviceId,
  useServiceImage,
  imageStyle = {},
}: Props) {
  const {url: fileUrl} = useAppImage(value, EnvironmentProfile.DRAFT, serviceId, useServiceImage);

  return (
    <YStack gap="$spacing-sm">
      <XStack alignItems="center" gap="$spacing-sm">
        <UploadButton
          value={value}
          onChange={(file) => {
            if (file && !fileIsImage(file)) {
              onChange?.(undefined);
            } else {
              onChange?.(file);
            }
          }}
          loading={loading}
          disabled={disabled}
          accept="image/png, image/jpeg">
          {showRemoveLabel && <Trans i18nKey="upload" />}
        </UploadButton>

        {showRemoveButton && value && (
          <Button
            size="sm"
            destructive
            hierarchy="link-color"
            onPress={() => onChange?.(undefined)}
            prefix={<IconView icon="Cancel01Icon" />}
          />
        )}
      </XStack>

      {showPreview && value && (
        <img src={fileUrl} style={{width: '150px', maxHeight: '250px', borderRadius: '10px', ...imageStyle}} />
      )}
    </YStack>
  );
}

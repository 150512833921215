import React, {Fragment, useCallback, useRef, useState} from 'react';

import {ScrollView, Dimensions, Platform, NativeSyntheticEvent, NativeScrollEvent} from 'react-native';

import {isDefined} from '../../utils/isDefined';
import {NoDataMessage} from '../NoDataMessage';
import {SectionLoader} from '../PageLoader';
import {Pagination} from '../Pagination';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {Cell} from './components/Cell';
import {ColumnHeader} from './components/ColumnHeader';
import {Row} from './components/Row';
import {RowExtend} from './components/RowExtend';
import {RowHeader} from './components/RowHeader';
import {useRowSelector, RowSelector} from './components/RowSelector';
import {TableHeader} from './components/TableHeader';
import {useColumnsSelector} from './hooks/useColumnsSelector';
import {SortedBy, TableProps} from './model';
import {filterByQuery} from './utils/filterByQuery';
import {sortByMultipleKeys} from './utils/sortByMultipleKeys';

export * from './model';
export {Cell, Row};

const windowWidth = Dimensions.get('window').width;
const actionsColumnWidth = 100;

export function Table<T>({
  title,
  columns,
  data,
  formatters,
  filterBy,
  stickyHeader = true,
  sortBy,
  filterInputProps,
  totalItems = 0,
  onFilter,
  onSort,
  actions,
  onSelect,
  onPagination,
  extendedView,
  isLoading,
  hasNoData,
  itemPrimaryKey,
  minContentHeight = 44,
  showIndices,
  footer,
  hidePagination,
  subRow,
  rowProps,
  cellProps,
  defaultValue = '-',
  paginationProps,
  ...props
}: TableProps<T>) {
  const [filteredData, setFilteredData] = useState<T[]>();
  const [sortedData, setSortedData] = useState<T[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageSize = paginationProps?.totalRecordsPerPage || 5;

  const [extendedItem, setExtendedItem] = useState<T>();
  const sortedBy = useRef<SortedBy<T>>(new Map());

  const {selectAll, onToggleSelectAll, onSelectItem, isItemSelected} = useRowSelector<T>(onSelect);

  const isMobile = Platform.OS !== 'web';
  const [rowHeightsByIndex, setRowHeightsByIndex] = useState<{[key: string | number]: number} | null>(null);

  const columnWidthSm = actions && rowHeightsByIndex ? windowWidth - actionsColumnWidth : windowWidth;

  const onRowLayouts = useCallback((e, idx?: number) => {
    e.persist?.();
    const key = isMobile ? idx : e?.nativeEvent?.target?.id;
    setRowHeightsByIndex((prev) => {
      return {...prev, [key]: e?.nativeEvent?.layout?.height};
    });
  }, []);

  const columnsSelector = useColumnsSelector<T>(columns);

  const defaultFilter = (value: string) => {
    const filteredData = filterByQuery(value, filterBy, data);
    setFilteredData(filteredData === data ? undefined : filteredData);
  };

  const defaultSort = (sortBy: SortedBy<T>) => {
    const unsortedData = [...(filteredData || data || [])];
    const [sortedData, didChange] = sortByMultipleKeys(unsortedData, sortBy);
    setSortedData(didChange ? sortedData : undefined);
  };

  let displayedData = sortedData || filteredData || data;

  const localPagination = !onPagination && currentPageSize;
  if (localPagination) {
    const paginationStart = (currentPage - 1) * currentPageSize;
    displayedData = displayedData?.slice(paginationStart, paginationStart + currentPageSize);
  }

  const filterable = filterBy?.length || onFilter;
  const loading = isDefined(isLoading) ? isLoading : !isDefined(data);
  const empty = isDefined(hasNoData) ? hasNoData : data?.length === 0;

  const scrollViewRef = useRef<ScrollView | null>(null);

  const scrollToPosition = (position: number) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: position, animated: false});
    }
  };

  const handleMomentumScrollEnd = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const scrollEndPosition = event.nativeEvent.contentOffset.x;
    const columnsNum = columnsSelector.displayedColumns.length;

    const columnIndex = Math.floor(scrollEndPosition / columnWidthSm);
    const targetScrollPosition = columnIndex * columnWidthSm;
    const middleOfColumn = columnIndex * columnWidthSm + columnWidthSm / 2;

    if (scrollEndPosition > middleOfColumn) {
      const nextColumnIndex = Math.min(columnIndex + 1, columnsNum - 1);
      const nextColumnScrollPosition = nextColumnIndex * columnWidthSm;
      scrollToPosition(nextColumnScrollPosition);
    } else {
      scrollToPosition(targetScrollPosition);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    onPagination?.(newPage);
  };

  return (
    <YStack
      theme="translucent"
      borderRadius="$8"
      borderWidth={1}
      borderColor="$border-primary"
      overflow="hidden"
      {...props}>
      <ScrollView showsHorizontalScrollIndicator={false}>
        {(title || filterable) && (
          <TableHeader
            columns={columns}
            columnsSelector={columnsSelector}
            title={title}
            filterable={filterable}
            onFilter={onFilter}
            defaultFilter={defaultFilter}
            filterInputProps={filterInputProps}
          />
        )}

        <XStack>
          <ScrollView
            scrollEnabled={true}
            ref={scrollViewRef}
            onMomentumScrollEnd={handleMomentumScrollEnd}
            pagingEnabled
            horizontal
            showsHorizontalScrollIndicator={Platform.OS === 'web'}
            contentContainerStyle={{flexGrow: 1, flexDirection: 'column'}}
            scrollEventThrottle={16}
            decelerationRate="fast">
            {stickyHeader && (
              <RowHeader
                onSelect={onSelect}
                showIndices={showIndices}
                columns={columnsSelector.displayedColumns}
                sortBy={sortBy}
                selectAll={selectAll}
                sortedBy={sortedBy}
                defaultSort={defaultSort}
                onSort={onSort}
                extendedView={extendedView}
                onToggleSelectAll={onToggleSelectAll}
                columnHeaderProps={{$sm: {width: columnWidthSm}}}
              />
            )}

            <ScrollView>
              <YStack width="100%">
                {!stickyHeader && (
                  <RowHeader
                    onSelect={onSelect}
                    showIndices={showIndices}
                    columns={columnsSelector.displayedColumns}
                    sortBy={sortBy}
                    selectAll={selectAll}
                    sortedBy={sortedBy}
                    defaultSort={defaultSort}
                    onSort={onSort}
                    extendedView={extendedView}
                    onToggleSelectAll={onToggleSelectAll}
                    columnHeaderProps={{$sm: {width: columnWidthSm}}}
                  />
                )}
                <YStack
                  justifyContent="flex-start"
                  width="100%"
                  minHeight={minContentHeight || 44}
                  backgroundColor="$bg-primary">
                  {loading && <SectionLoader isLoading overlayMode />}
                  {!loading && empty && <NoDataMessage hasNoData />}
                  {displayedData?.map((item, index) => {
                    const rowExtended = item === extendedItem;

                    return (
                      <Fragment key={(itemPrimaryKey && (item[itemPrimaryKey] as any)) || index}>
                        <Row
                          index={index}
                          subRow={subRow?.(item)}
                          {...(rowProps?.(item) || {})}
                          backgroundColor={rowExtended ? '$bg-secondary' : undefined}
                          onLayout={onRowLayouts}
                          rowExtended={rowExtended}
                          extendedView={extendedView}
                          item={item}
                          id={index + ''}>
                          {extendedView && (
                            <RowExtend
                              extended={rowExtended}
                              onToggle={() => setExtendedItem(rowExtended ? undefined : item)}
                            />
                          )}
                          {onSelect && (
                            <RowSelector selected={isItemSelected(item)} onSelectToggle={() => onSelectItem(item)} />
                          )}
                          {showIndices && (
                            <Cell flex={0} width="$3">
                              {currentPageSize * (currentPage - 1) + index + 1}
                            </Cell>
                          )}

                          {columnsSelector.displayedColumns.map((column, index) => {
                            const formatter = formatters?.[column.field];
                            const value = item[column.field];
                            return (
                              <Cell
                                key={index}
                                width={column.width}
                                minWidth={100}
                                $sm={{width: columnWidthSm}}
                                {...(cellProps?.(item, column.field) || {})}>
                                {(formatter ? formatter({value, item}) : value) ?? (
                                  <Typography>{defaultValue}</Typography>
                                )}
                              </Cell>
                            );
                          })}
                        </Row>
                      </Fragment>
                    );
                  })}
                  {footer}
                </YStack>
              </YStack>
            </ScrollView>
          </ScrollView>

          {actions && rowHeightsByIndex && (
            <YStack width={actionsColumnWidth}>
              <Row backgroundColor="$bg-secondary" alignItems="center">
                <ColumnHeader>
                  <CoreTrans i18nKey="actions" />
                </ColumnHeader>
              </Row>
              {displayedData?.map((item, index) => {
                const backgroundColor = index % 2 === 0 ? '$bg-primary' : '$bg-secondary';
                return (
                  <XStack
                    alignItems="center"
                    justifyContent="center"
                    borderBottomWidth={0.5}
                    borderColor="$border-secondary"
                    key={index}
                    height={rowHeightsByIndex?.[index]}
                    backgroundColor={backgroundColor}>
                    {actions(item)}
                  </XStack>
                );
              })}
            </YStack>
          )}
        </XStack>

        {!hidePagination && (
          <Pagination
            currentPage={empty ? 0 : currentPage}
            totalRecords={empty ? 0 : onPagination ? totalItems || data?.length : data?.length}
            totalRecordsPerPage={currentPageSize}
            onPageChange={handlePageChange}
            displayCount={{start: 1, end: 10}}
            {...paginationProps}
          />
        )}
      </ScrollView>
    </YStack>
  );
}

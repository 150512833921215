import React from 'react';

import {useTranslation} from 'react-i18next';
import {Stack, useMedia, XStack} from 'tamagui';

import {coreScopedKey, useAppDirection} from '../../../utils';
import {dayjs} from '../../../utils/extendedDayjs';
import {BidirectionalIcon} from '../../BidirectionalIcon';
import {Button} from '../../Button';
import {getLocalizedText} from '../../LocalizedText';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {hijriMonths} from '../config';
import {DATE_PICKER_CONTENT_WIDTH} from '../constants';
import {useDatepickerContext} from '../context/DatepickerProvider';
import {OnDatesChangeProps, useMonth, UseMonthProps} from '../hooks';
import {CalendarType} from '../models';

import {DatePickerValueInput} from './DatePickerValueInput';
import {Day} from './Day';

type Props = UseMonthProps & {
  monthsCount: number;
  isFirstMonth: boolean;
  isLastMonth: boolean;
  type: CalendarType;
  date: Date;
  isRangePicker: boolean;
  state: OnDatesChangeProps;
};

export function DatepickerContent({
  type,
  monthsCount,
  isFirstMonth,
  isRangePicker,
  isLastMonth,
  date,
  state,
  ...props
}: Props) {
  const datepickerContext = useDatepickerContext();
  const {t, i18n} = useTranslation();
  const media = useMedia();
  const {isLTR} = useAppDirection();
  const {
    goToPreviousYear,
    goToPreviousMonths,
    goToPreviousMonthsByOneMonth,
    goToNextMonthsByOneMonth,
    goToNextMonths,
    goToNextYear,
  } = datepickerContext;

  const {days, weekdayLabels, monthLabel} = useMonth({
    ...props,
    monthLabelFormat(date: Date) {
      if (type === 'gregory') {
        return dayjs(date).format('MMMM');
      }
      const hijriMonth = hijriMonths[+dayjs(date).calendar('hijri').format('MM') - 1];
      return getLocalizedText({value: coreScopedKey(`hidjriMonths.${hijriMonth}`), t, i18n});
    },
    dayLabelFormat(date) {
      return dayjs(date).calendar(type).format('DD');
    },
    weekdayLabelFormat(date) {
      return dayjs(date).format('dd');
    },
  });

  const getBorderProps = (isLTR: boolean) => ({
    ...(isLTR
      ? {borderLeftWidth: isRangePicker && media.gtMd ? 1 : 0, borderLeftColor: '$border-secondary'}
      : {borderRightWidth: isRangePicker && media.gtMd ? 1 : 0, borderRightColor: '$border-secondary'}),
  });

  return (
    <View
      width={DATE_PICKER_CONTENT_WIDTH}
      borderWidth={0}
      borderBottomWidth={1}
      borderBottomColor="$border-secondary"
      alignItems="center"
      justifyContent="center"
      gap="$spacing-md"
      {...getBorderProps(isLTR)}>
      <XStack width="100%" justifyContent="space-evenly" alignItems="center" paddingTop="$3">
        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          focusable={false}
          circular
          onPress={() => goToPreviousYear(10)}
          prefix={<BidirectionalIcon icon="ArrowLeftDoubleIcon" size={16} color="$fg-quinary" />}
        />
        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          circular
          focusable={false}
          onPress={() => goToPreviousYear(1)}
          prefix={<BidirectionalIcon icon="ArrowLeft01Icon" size={16} color="$fg-quinary" />}
        />
        <Typography>{dayjs(date).calendar(type).format('YYYY')}</Typography>
        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          focusable={false}
          onPress={() => goToNextYear(1)}
          prefix={<BidirectionalIcon icon="ArrowRight01Icon" size={16} color="$fg-quinary" />}
          circular
        />
        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          circular
          focusable={false}
          onPress={() => goToNextYear(10)}
          prefix={<BidirectionalIcon icon="ArrowRightDoubleIcon" size={16} color="$fg-quinary" />}
        />
      </XStack>
      <XStack width="100%" justifyContent="space-evenly" alignItems="center" height={40}>
        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          focusable={false}
          opacity={isFirstMonth ? undefined : 0}
          disabled={!isFirstMonth}
          onPress={monthsCount > 1 ? goToPreviousMonths : goToPreviousMonthsByOneMonth}
          prefix={<BidirectionalIcon icon="ArrowLeft01Icon" size={18} color="$fg-quinary" />}
          circular
        />

        <Typography fontSize="$text-md" fontWeight="600">
          {monthLabel}
        </Typography>

        <Button
          borderWidth={0}
          hierarchy="secondary-color"
          focusable={false}
          opacity={isLastMonth ? undefined : 0}
          disabled={!isLastMonth}
          onPress={monthsCount > 1 ? goToNextMonths : goToNextMonthsByOneMonth}
          prefix={<BidirectionalIcon icon="ArrowRight01Icon" size={18} color="$fg-quinary" />}
          circular
        />
      </XStack>

      <DatePickerValueInput
        type={type}
        state={{
          startDate: state.startDate,
          endDate: state.endDate,
        }}
        isRangePicker={isRangePicker}
        showContent={!isRangePicker || (isRangePicker && media.sm)}
      />

      <XStack alignItems="center" height={40} width={280} $xs={{width: '100%'}}>
        {weekdayLabels.map((dayLabel) => (
          <Typography fontWeight="500" width={`${100 / 7}%`} key={dayLabel} textAlign="center" fontSize="$text-sm">
            {dayLabel}
          </Typography>
        ))}
      </XStack>

      <XStack display="flex" flexWrap="wrap" height={260} width={280} $xs={{width: '100%'}}>
        {days.map((day, index) => {
          if (typeof day === 'object') {
            return (
              <Day
                datepickerContext={datepickerContext}
                dayLabel={day.dayLabel}
                date={day.date}
                key={day.date.toString()}
                isCurrentMonth={day.isCurrentMonth}
              />
            );
          }
          return <Stack key={index} width={`${100 / 7}%`} />;
        })}
      </XStack>
      <XStack display="flex"></XStack>
    </View>
  );
}

import React from 'react';

import {SketchPicker} from 'react-color';

import {Popover} from '../Popover';
import {View, ViewProps} from '../View';
import {XStack} from '../XStack';

export type ColorInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  size?: 'sm' | 'md' | 'lg';
} & ViewProps;

export const ColorInput = ({value, onChange, size = 'sm', width, height, ...props}: ColorInputProps) => {
  const getInputStylesBaedONSize = (size: ColorInputProps['size'] = 'sm'): ViewProps => {
    const _sizesStyle = {
      sm: {
        height: 36,
        width: 36,
      },
      md: {
        height: 40,
        width: 40,
      },
      lg: {
        height: 44,
        width: 44,
      },
    };

    return _sizesStyle[size];
  };
  return (
    <XStack>
      <Popover
        contentProps={{padding: 0}}
        triggerProps={{
          ...getInputStylesBaedONSize(size),
          ...(width ? {width: width} : {}),
          ...(height ? {height: height} : {}),
        }}
        trigger={
          <View
            borderRadius="$rounded-md"
            overflow="hidden"
            borderColor="$border-primary"
            borderWidth={1}
            width="100%"
            height="100%"
            {...props}>
            <View backgroundColor={value} height="100%" width="100%"></View>
          </View>
        }>
        <SketchPicker
          color={value || 'grey'}
          onChangeComplete={(c) => onChange?.(`${c.hex}${decimalToHex(c.rgb.a || 100)}`)}
        />
      </Popover>
    </XStack>
  );
};

const decimalToHex = (alpha: number) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));

import React, {useState, useImperativeHandle, forwardRef, useMemo} from 'react';

import {Tabs as BaseTabs} from 'tamagui';

import {useResponsiveValue} from '../../utils';
import {useAppDirection} from '../../utils/useAppDirection';
import {IconView} from '../IconView';
import {Select} from '../Select';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {AnimatedYStack} from './components/AnimatedYStack';
import {BaseTabsList} from './components/BaseTabsList';
import {TabsProps, TabsState, TabsTabProps} from './types';

export * from './types';

export const _Tabs = (
  {
    tabs = [],
    defaultTab,
    contentProps,
    tabsProps,
    type = 'buttonBorder',
    orientation = 'horizontal',
    onChange,
    ...props
  }: TabsProps,
  ref,
) => {
  const [tabState, setTabState] = useState<TabsState>({
    activeAt: null,
    currentTab: defaultTab || 0,
  });

  const {direction: appDirection} = useAppDirection();
  const isMobileViewport = useResponsiveValue({$sm: true, $md: false, $lg: false});

  const setCurrentTab = (currentTab: number) => setTabState({...tabState, currentTab});
  const setActiveIndicator = (activeAt) => setTabState({...tabState, activeAt});
  const {activeAt, currentTab} = tabState;

  const handleOnInteraction: TabsTabProps['onInteraction'] = (type, layout) => {
    if (type === 'select') {
      setActiveIndicator(layout);
    }
  };

  useImperativeHandle(ref, () => ({
    switchToTab: (tabIndex: number) => {
      setCurrentTab(tabIndex);
    },
  }));

  const selectTabs = useMemo(() => {
    return isMobileViewport && tabs.length
      ? tabs.map((t) => ({
          label: t.label,
          prefix: <IconView icon={t.icon} />,
          suffix: t.suffix,
          value: t.label,
        }))
      : [];
  }, [isMobileViewport, tabs]);

  return (
    <BaseTabs
      value={'' + currentTab || '' + defaultTab}
      orientation={orientation}
      flex={1}
      width="100%"
      marginTop="$2"
      flexDirection={orientation === 'horizontal' ? 'column' : 'row'}
      activationMode="manual"
      borderRadius="$4"
      onValueChange={(value: string) => {
        setCurrentTab(+value);
        onChange && onChange(value);
      }}
      {...props}>
      <YStack alignItems="center" justifyContent="center">
        {isMobileViewport ? (
          <Select
            onChange={(value) => {
              const index = tabs.findIndex((t) => t.label === value);
              setCurrentTab(index);
              onChange && onChange(value);
            }}
            options={selectTabs}
            value={tabs[currentTab].label}
          />
        ) : (
          <XStack alignItems="center" justifyContent="center" flex={1} width="100%" height="100%" overflow="scroll">
            <BaseTabsList
              tabs={tabs}
              appDirection={appDirection}
              currentTab={currentTab}
              activeAt={activeAt}
              handleOnInteraction={handleOnInteraction}
              type={type}
              orientation={orientation}
              {...tabsProps}
            />
          </XStack>
        )}
      </YStack>
      <AnimatedYStack key="animatedYStack" animation="100ms" x={0} opacity={1}>
        <BaseTabs.Content direction={appDirection} value={'' + currentTab} forceMount flex={1} {...contentProps}>
          {tabs?.length && tabs[currentTab]?.content}
        </BaseTabs.Content>
      </AnimatedYStack>
    </BaseTabs>
  );
};

export const Tabs = forwardRef(_Tabs);

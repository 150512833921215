import {dayjs} from '../../../utils/extendedDayjs';
import {ValueType} from '../../DatePicker/models';

export const formatInputValue = (value: ValueType | ValueType[], format: string) => {
  return !Array.isArray(value)
    ? typeof value === 'string'
      ? value
      : dayjs(value).format(format)
    : typeof value === 'string'
    ? value[0]
    : dayjs(value[0]).format(format) + '-' + dayjs(value[1]).format(format);
};

import {AlertType} from '../types';

export const AlertTypeConfig: Record<'info' | 'success' | 'danger' | 'warning', AlertType> = {
  info: {
    color: '$utility-blue-600',
    icon: 'InformationCircleIcon',
    backgroundColor: '$bg-primary-alt',
  },
  success: {
    color: '$fg-success-primary',
    icon: 'CheckmarkCircle02Icon',
    backgroundColor: '$bg-primary-alt',
  },
  danger: {
    color: '$fg-error-primary',
    icon: 'InformationCircleIcon',
    backgroundColor: '$bg-primary-alt',
  },
  warning: {
    color: '$fg-warning-primary',
    icon: 'InformationCircleIcon',
    backgroundColor: '$bg-primary-alt',
  },
} as const;

import React from 'react';

import {Platform} from 'react-native';

import {MobileSelect} from './components/MobileSelect';
import {WebSelect} from './components/WebSelect';
import {SelectProps} from './types';

export * from './types';
export * from './components/SelectOption';
export * from './components/SelectWrapper';
export * from './components/Trigger';
export * from './hooks/useSelectIsOpen';
export * from './hooks/useSelectSearch';
export * from './components/TriggerContainer';
export * from './components/SelectIcon';

// TODO: refactor to one implemntation for web and mobile
export const Select = (props: SelectProps) => {
  if (Platform.OS === 'web') {
    return <WebSelect {...props} />;
  }

  return <MobileSelect {...props} />;
};

import React from 'react';

import {IconView} from '../../IconView';
import {ListItem} from '../../ListItem';
import {LocalizedText} from '../../LocalizedText';
import {SelectOptionProps} from '../types';

export type OptionProps = {
  option?: SelectOptionProps;
  onChangeSelection?: (value?: SelectOptionProps) => void;
  isSelected?: boolean;
};

export const SelectOption = ({option, onChangeSelection, isSelected}: OptionProps) => {
  return (
    <ListItem
      onPress={onChangeSelection ? () => onChangeSelection?.(option) : undefined}
      backgroundColor={isSelected ? '$bg-active' : '$bg-primary'}
      prefix={option?.prefix}
      title={<LocalizedText value={option?.label} />}
      disabled={option?.disabled}
      marginBottom="$spacing-xxs"
      suffix={
        isSelected ? (
          <IconView color={option?.disabled ? '$fg-disabled' : '$fg-brand-primary'} icon="Tick02Icon" size={15} />
        ) : (
          option?.suffix
        )
      }
    />
  );
};

import React from 'react';

import {ColorTokens} from 'tamagui';

import {IconView} from '../../IconView';

export const AccordionArrow = ({
  isActive,
  isFilledAndActive,
  iconColor,
}: {
  isActive: boolean;
  isFilledAndActive: boolean;
  iconColor: ColorTokens;
}) => (
  <IconView
    icon={isActive ? 'ArrowUp01Icon' : 'ArrowDown01Icon'}
    size={24}
    color={isFilledAndActive ? '$textColor' : iconColor}
  />
);

import React, {forwardRef, useMemo, useState} from 'react';

import {OpaqueColorValue} from 'react-native';
import BaseAccordion, {AccordionProps as RNAccordionProps} from 'react-native-collapsible/Accordion';
import {ColorTokens, ThemeValueFallback, XStack, YStack} from 'tamagui';

import {Typography} from '../Typography';
import {View, ViewProps} from '../View';

import {AccordionArrow} from './components/AccordionArrow';
import {AccordionConfig} from './config';
import {AccordionSection, AccordionVariants} from './models';

export * from './models';

// todo : Use Tamagui Accordion .

export type AccordionProps = Omit<
  RNAccordionProps<any>,
  'sections' | 'renderHeader' | 'renderFooter' | 'renderSectionTitle' | 'renderContent' | 'onChange' | 'activeSections'
> & {
  sections: AccordionSection[];
  onSectionsChange?: RNAccordionProps<AccordionSection>['onChange'];
  defaultActiveSections?: number[];
  headerBackgroundColor?: ThemeValueFallback | ColorTokens | OpaqueColorValue;
  variant?: AccordionVariants;
  containerProps?: ViewProps;
  contentContainerProps?: ViewProps;
  headerContainerProps?: ViewProps;
  action?: React.ReactNode;
  customeHeader?: (
    content: AccordionSection,
    index: number,
    isActive: boolean,
    sections: AccordionSection[],
  ) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

export const _Accordion = (
  {
    sections,
    onSectionsChange,
    customeHeader,
    defaultActiveSections = [0],
    headerBackgroundColor,
    variant = 'filled',
    containerProps,
    contentContainerProps,
    headerContainerProps,
    action,
    ...props
  }: AccordionProps,
  ref,
) => {
  const [activeSections, setActiveSections] = useState(defaultActiveSections);

  const headerBackground = AccordionConfig[variant].headerBackground;
  const openedHeaderBackground = AccordionConfig[variant].openedHeaderBackground;
  const padding = AccordionConfig[variant].padding;
  const contentPadding = AccordionConfig[variant].contentPadding;

  const headerTextColor = AccordionConfig[variant].headerTextColor;
  const iconColor = AccordionConfig[variant].iconColor;

  const displayedSections = useMemo(() => sections.filter((s) => s.display !== false), [sections]);

  const renderHeader = (section: AccordionSection, _: number, isActive: boolean, __: AccordionSection[]) => {
    const isFilledAndActive = variant === 'filled' && isActive;
    const isOutlined = variant === 'outlined';

    return (
      <XStack
        theme="translucent"
        alignItems="center"
        borderRadius="$5"
        backgroundColor={headerBackgroundColor || (isActive ? openedHeaderBackground : headerBackground)}
        margin="$2"
        paddingHorizontal="$4"
        paddingVertical="$4"
        {...headerContainerProps}>
        <XStack
          alignItems="center"
          flex={1}
          gap="$2"
          justifyContent={isOutlined ? 'space-between' : 'flex-end'}
          flexDirection={isOutlined ? 'row' : 'row-reverse'}>
          <YStack marginHorizontal="$0.5" maxWidth="90%">
            <Typography
              width="100%"
              variant={variant === 'filled' ? 'body1' : 'h6'}
              fontWeight="700"
              color={isFilledAndActive ? '$textColor' : headerTextColor}>
              {section.title}
            </Typography>
            {section.subTitle && (
              <Typography width="100%" color={isFilledAndActive ? '$textColor' : headerTextColor} variant="body2">
                {section.subTitle}
              </Typography>
            )}
          </YStack>

          {isOutlined ? (
            <XStack gap="$2" alignItems="center">
              {action}
              <AccordionArrow isActive={isActive} isFilledAndActive={isFilledAndActive} iconColor={iconColor} />
            </XStack>
          ) : (
            <AccordionArrow isActive={isActive} isFilledAndActive={isFilledAndActive} iconColor={iconColor} />
          )}
        </XStack>

        {!isOutlined && (
          <XStack alignItems="center" justifyContent="flex-end">
            {action}
          </XStack>
        )}
      </XStack>
    );
  };

  const renderContent = (section: AccordionSection) => {
    return (
      <View margin={contentPadding} {...contentContainerProps}>
        {typeof section.content === 'string' ? <Typography>{section.content}</Typography> : section.content}
      </View>
    );
  };

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
    onSectionsChange && onSectionsChange(activeSections);
  };

  return (
    <View
      ref={ref}
      theme="translucent"
      paddingTop="$0.5"
      padding={padding}
      borderRadius="$true"
      borderColor="$border"
      borderWidth={1}
      backgroundColor="$background"
      marginVertical="$0.5"
      {...containerProps}>
      <BaseAccordion
        sections={displayedSections}
        renderContent={renderContent}
        activeSections={activeSections}
        onChange={updateSections}
        renderHeader={(...args) => customeHeader?.(...args) || renderHeader(...args)}
        underlayColor="transparent"
        containerStyle={{borderRadius: 8}}
        {...props}
      />
    </View>
  );
};

export const Accordion = forwardRef(_Accordion);

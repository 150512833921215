import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {XStack} from '../../../../XStack';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaulProps';
import {RowElementProps} from './types';

export const Component = ({children, ...props}: RowElementProps, ref) => {
  return (
    <XStack {...props} ref={ref}>
      {children}
    </XStack>
  );
};

export const RowComponent = forwardRef(Component);

export const RowElement: BlockDefinition<RowElementProps> = {
  name: coreScopedKey('blocks.RowElement'),
  version: 1,
  id: 'RowElement',
  props: defaultProps,
  children: [],
  Component: RowComponent,
  category: BlockCategory.LAYOUT,
  Icon: 'Layout2RowIcon',
  propsBuilder: [LayoutPropsBuilder],
};

import React from 'react';

import {useAppDirection} from '../../utils';
import {Button} from '../Button';
import {Checkbox} from '../Checkbox';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';

import {getTagStyles} from './styles';
import {TagProps} from './types';

export * from './types';

export const Tag = ({
  label,
  icon,
  size = 'sm',
  hasCheckbox = false,
  checkboxProps,
  count,
  closable = false,
  onClose,
  ...props
}: TagProps) => {
  const {isRTL} = useAppDirection();

  const styles = getTagStyles({
    size,
    isRTL,
    hasIcon: !!icon,
    hasCheckbox,
    hasCounter: count !== undefined,
    closable,
  });

  return (
    <XStack {...styles.container} {...props}>
      {hasCheckbox && <Checkbox size={size} {...checkboxProps} />}
      {icon && <View>{icon}</View>}
      <Typography {...styles.text}>{label}</Typography>
      {count !== undefined && (
        <View {...styles?.counter?.container}>
          <Typography {...styles?.counter?.text}>{count}</Typography>
        </View>
      )}
      {closable && (
        <Button
          prefix={<IconView icon="CancelIcon" {...styles?.closeIcon?.icon} />}
          size={size}
          hierarchy="tertiary-gray"
          onPress={onClose}
          {...styles?.closeIcon?.container}
        />
      )}
    </XStack>
  );
};

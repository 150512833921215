import React from 'react';

import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

import {DropdownItemProps} from './types';

export function DropdownItem({disabled, onPress, icon, children, ...props}: DropdownItemProps) {
  return (
    <XStack
      justifyContent="space-between"
      alignItems="center"
      paddingHorizontal="$spacing-xl"
      paddingVertical={1}
      minHeight={38}
      hoverStyle={{
        backgroundColor: '$bg-primary-hover',
      }}
      pressStyle={{
        backgroundColor: '$bg-primary-hover',
      }}
      cursor="pointer"
      disabled={disabled}
      disabledStyle={{
        cursor: 'not-allowed',
        backgroundColor: '$bg-disabled',
        opacity: 0.3,
      }}
      onPress={onPress}
      {...props}>
      <XStack gap="$spacing-md" alignItems="center" flexShrink={1}>
        {icon && <IconView icon={icon} size={16} color="$fg-quaternary" />}
        <Typography color="$text-secondary" fontWeight="600" fontSize="$text-sm">
          {children}
        </Typography>
      </XStack>
    </XStack>
  );
}

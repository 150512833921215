import {ColorTokens} from 'tamagui';

import {BadgeGroupColor, BadgeGroupSizes, BadgeGroupStylesProps, BadgeGroupTheme, BadgePosition} from '../types';

export type BadgeColorsStyles = {
  [color in BadgeGroupColor]: BadgeGroupStylesProps & {
    iconColor?: ColorTokens;
  };
};

export type BadgeSizeStyles = {
  [size in BadgeGroupSizes]: BadgeGroupStylesProps;
};

export type BadgeStyle = {
  container: BadgeGroupStylesProps;
  text: BadgeGroupStylesProps;
  icon: {
    color: ColorTokens;
  };
};

export const getBadgeStyles = (
  theme: BadgeGroupTheme,
  color: BadgeGroupColor,
  size: BadgeGroupSizes,
  badgePosition: BadgePosition = 'leading',
  isRTL: boolean,
): BadgeStyle => {
  const _stylesBaseOnSize = getStylesBaseOnSize(size);
  const _themeColorStyles = getThemeColorStyles(theme, color);

  const _textStyles = {
    fontSize: _stylesBaseOnSize.fontSize,
    color: _themeColorStyles.color,
  };

  delete _stylesBaseOnSize.fontSize;
  delete _themeColorStyles.color;

  return {
    container: {
      width: 'max-content',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      ..._themeColorStyles,
      ..._stylesBaseOnSize,
      ...getBorderRadius(theme),
      ...getPaddingStartEnd(badgePosition, isRTL),
    },
    text: {
      fontWeight: '500',
      ..._textStyles,
    },
    icon: {
      color: _themeColorStyles?.iconColor || '$utility-gray-500',
    },
  };
};

function getPaddingStartEnd(badgePosition: BadgePosition = 'leading', isRTL: boolean) {
  let _paddingLeft;
  let _paddingRight;

  if (badgePosition === 'leading') {
    _paddingRight = 10;
  }

  if (badgePosition === 'trailing' && isRTL) {
    _paddingRight = undefined;
  }

  if (badgePosition === 'trailing') {
    _paddingLeft = 10;
  }

  if (badgePosition === 'leading' && isRTL) {
    _paddingLeft = undefined;
  }

  return {
    paddingRight: _paddingRight,
    paddingLeft: _paddingLeft,
  };
}

function getThemeColorStyles(
  theme: BadgeGroupTheme,
  color: BadgeGroupColor,
): BadgeGroupStylesProps & {
  iconColor?: ColorTokens;
} {
  const _colorsStyles: BadgeColorsStyles = {
    gray: {
      borderColor: '$utility-gray-200',
      backgroundColor: '$utility-gray-50',
      color: '$utility-gray-700',
      iconColor: '$utility-gray-500',
      hoverStyle: {
        backgroundColor: '$utility-gray-100',
      },
    },
    brand: {
      borderColor: '$utility-brand-200',
      backgroundColor: '$utility-brand-50',
      color: '$utility-brand-700',
      iconColor: '$utility-brand-500',
      hoverStyle: {
        backgroundColor: '$utility-brand-100',
      },
    },
    error: {
      borderColor: '$utility-error-200',
      backgroundColor: '$utility-error-50',
      color: '$utility-error-700',
      iconColor: '$utility-error-500',
      hoverStyle: {
        backgroundColor: '$utility-error-100',
      },
    },
    warning: {
      borderColor: '$utility-warning-200',
      backgroundColor: '$utility-warning-50',
      color: '$utility-warning-700',
      iconColor: '$utility-warning-500',
      hoverStyle: {
        backgroundColor: '$utility-warning-100',
      },
    },
    success: {
      borderColor: '$utility-success-200',
      backgroundColor: '$utility-success-50',
      color: '$utility-success-700',
      iconColor: '$utility-success-500',
      hoverStyle: {
        backgroundColor: '$utility-success-100',
      },
    },
  };

  return {
    ..._colorsStyles[color],
    ...(theme === 'outline' && getOutlineThemeBorderColors(color)),
    ...(theme === 'modern' && {
      backgroundColor: '$bg-primary',
      borderColor: '$border-primary',
      color: '$text-secondary',
      hoverStyle: {
        backgroundColor: '$bg-secondary',
      },
    }),
  };
}

function getOutlineThemeBorderColors(color: BadgeGroupColor): BadgeGroupStylesProps {
  const _pillOutlinetypeBorderColors: BadgeColorsStyles = {
    gray: {
      borderColor: '$utility-gray-600',
      backgroundColor: '$bg-primary',
      hoverStyle: {
        backgroundColor: '$utility-gray-50',
      },
    },
    brand: {
      borderColor: '$utility-brand-600',
      backgroundColor: '$bg-primary',
      hoverStyle: {
        backgroundColor: '$utility-brand-50',
      },
    },
    error: {
      borderColor: '$utility-error-600',
      backgroundColor: '$bg-primary',
      hoverStyle: {
        backgroundColor: '$utility-error-50',
      },
    },
    warning: {
      backgroundColor: '$bg-primary',
      borderColor: '$utility-warning-600',
      hoverStyle: {
        backgroundColor: '$utility-warning-50',
      },
    },
    success: {
      borderColor: '$utility-success-600',
      backgroundColor: '$bg-primary',
      hoverStyle: {
        backgroundColor: '$utility-success-50',
      },
    },
  };

  return _pillOutlinetypeBorderColors[color];
}

function getStylesBaseOnSize(size: BadgeGroupSizes): BadgeGroupStylesProps {
  const _sizeStyles: BadgeSizeStyles = {
    md: {
      gap: '$spacing-md',
      fontSize: '$text-xs',
    },
    lg: {
      gap: '$spacing-lg',
      fontSize: '$text-sm',
    },
  };
  return _sizeStyles[size];
}

function getBorderRadius(theme: BadgeGroupTheme): BadgeGroupStylesProps {
  const _typesBorderRadius: {
    [theme in BadgeGroupTheme]?: BadgeGroupStylesProps;
  } = {
    outline: {
      borderRadius: '$rounded-full',
    },
    light: {
      borderRadius: '$rounded-full',
    },
    modern: {
      borderRadius: '$rounded-lg',
    },
  };

  return {..._typesBorderRadius?.[theme]};
}

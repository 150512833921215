import React from 'react';

import {styled} from 'tamagui';

import {Pressable} from '../../Pressable';
import {View} from '../../View';
import {XStack} from '../../XStack';

const Step = styled(View, {
  backgroundColor: '$gray-200',
  variants: {
    size: {
      md: {
        width: 8,
        height: 8,
      },
      lg: {
        width: 10,
        height: 10,
      },
    },
    type: {
      dot: {
        borderRadius: '$rounded-full',
      },
      line: {
        borderRadius: '$rounded-md',
        width: 43,
        height: 6,
      },
    },
  } as const,

  defaultVariants: {
    size: 'md',
    type: 'dot',
  },
});

const ActiveStep = styled(Step, {
  backgroundColor: '$brand-600',
});

interface ProgressStepperProps {
  steps: number;
  currentStep?: number;
  onStepClick?: (step: number) => void;
  containerStyle?: React.ComponentProps<typeof XStack>;
  size?: 'md' | 'lg';
  type?: 'dot' | 'line';
}

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  steps,
  currentStep,
  onStepClick,
  containerStyle,
  size = 'md',
  type = 'dot',
}) => {
  return (
    <XStack gap={10} backgroundColor="$alpha-white-90" {...containerStyle}>
      {Array.from({length: steps}, (_, index) => (
        <Pressable key={index} onPress={() => onStepClick?.(index)}>
          {index === currentStep ? <ActiveStep /> : <Step size={size} type={type} />}
        </Pressable>
      ))}
    </XStack>
  );
};

export default ProgressStepper;

import React from 'react';

import {ColorTokens} from 'tamagui';

import {isReactComponent} from '../../../utils/isReactComponent';
import {Typography} from '../../Typography';
import {ViewProps} from '../../View';
import {XStack} from '../../XStack';

type Props = ViewProps & {color?: ColorTokens};

export const Cell = ({children, width, color, ...props}: Props) => {
  return (
    <XStack flex={1} width={width || '$10'} alignItems="center" paddingHorizontal="$5" height="100%" {...props}>
      {isReactComponent(children) ? (
        children
      ) : (
        <Typography color={color} fontSize="$text-sm" fontWeight="400">
          {children}
        </Typography>
      )}
    </XStack>
  );
};

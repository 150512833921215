import React from 'react';

import {Typography} from '../../Typography';
import {View} from '../../View';
import {XStack} from '../../XStack';
import {DatepickerContext} from '../context/DatepickerProvider';
import {getColor} from '../helpers/getColors';
import {useDay} from '../hooks';

type Props = {
  date: Date;
  dayLabel: string;
  datepickerContext: DatepickerContext;
  isCurrentMonth: boolean;
};

export function Day({dayLabel, date, datepickerContext, isCurrentMonth}: Props) {
  const {isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate, onClick, onMouseEnter} = useDay({
    date,
    ...datepickerContext,
  });

  if (!dayLabel) {
    return <XStack backgroundColor="$bg-brand-primary" display="flex" flex={1} />;
  }

  const {background, foreground} = getColor(
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    isCurrentMonth,
  )({
    selectedFirstOrLastColor: '$fg-brand-primary',
    normalColor: '$background',
    selectedColor: '$bg-active',
    rangeHoverColor: '$bg-active',
    disabledColor: '$transparent',
  });

  return (
    <View
      alignItems="center"
      justifyContent="center"
      height={40}
      onPress={onClick}
      onHoverIn={onMouseEnter}
      disabled={disabledDate}
      width={`${100 / 7}%`}
      paddingVertical="$2"
      cursor="pointer"
      borderRadius={isSelected && !isSelectedStartOrEnd ? 0 : '$rounded-full'}
      backgroundColor={background}>
      <Typography color={foreground} fontWeight="400" fontSize="$text-sm">
        {dayLabel}
      </Typography>
    </View>
  );
}

import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';

import {InputProps, Label, Subtext} from '../Input';
import {XStack} from '../XStack';

import {BaseInput} from './BaseInput';

export type OTPInputProps = Omit<
  InputProps,
  'onChange' | 'placeholder' | 'suffix' | 'prefix' | 'addOnBefore' | 'addOnAfter' | 'containerProps'
> & {
  length?: number;
  onChangeText?: (value: string) => void;
};

function strToArr(str: string) {
  return (str || '').split('');
}

const _OTP = (
  {value, onChangeText, length = 4, required, label, error, subtext, size, ...rest}: OTPInputProps,
  ref,
) => {
  const [cellsValue, setCellesValue] = useState<string[]>([]);
  const refs = useRef<Record<number, any | null>>({});
  const [activeInput, setActiveInput] = useState(0);

  useEffect(() => {
    if (value) {
      setCellesValue(strToArr(value));
    }
  }, [value]);

  useEffect(() => {
    if (ref && refs) {
      ref.current = refs?.current;
    }
  }, [refs, ref]);

  const patchValue = (index: number, txt: string) => {
    let nextCells = [...cellsValue];

    for (let i = 0; i < index; i += 1) {
      if (!nextCells[i]) {
        nextCells[i] = '';
      }
    }

    if (txt.length <= 1) {
      nextCells[index] = txt;
    } else {
      nextCells = nextCells.slice(0, index).concat(strToArr(txt));
    }
    nextCells = nextCells.slice(0, length);

    for (let i = nextCells.length - 1; i >= 0; i -= 1) {
      if (nextCells[i]) {
        break;
      }
      nextCells.pop();
    }

    const formattedValue = nextCells.map((c) => c || ' ').join('');

    nextCells = strToArr(formattedValue).map((c, i) => {
      if (c === ' ' && !nextCells[i]) {
        return nextCells[i];
      }
      return c;
    });

    return nextCells;
  };

  const _onChange = (index, value) => {
    const nextCells = patchValue(index, value);

    const nextIndex = Math.min(index + value.length, length - 1);
    if (nextIndex !== index && nextCells[index] !== undefined) {
      refs.current[nextIndex]?.focus();
      setActiveInput(nextIndex);
    }

    triggerValueCellsChange(nextCells);
  };

  const triggerValueCellsChange = (nextValueCells: string[]) => {
    setCellesValue(nextValueCells);

    if (
      onChangeText &&
      nextValueCells.length === length &&
      nextValueCells.every((c) => c) &&
      nextValueCells.some((c, index) => cellsValue[index] !== c)
    ) {
      onChangeText(nextValueCells.join(''));
    }
  };

  const getDimentions = useMemo(() => {
    if (size === 'md') {
      return {
        width: 80,
        height: 80,
      };
    }

    return {
      width: 64,
      height: 64,
    };
  }, [size]);

  return (
    <>
      <Label label={label} required={required} />

      <XStack gap="$spacing-md">
        {Array.from({length}).map((_, index) => {
          const key = `otp-${index}`;
          const inputValue = cellsValue?.[index];
          return (
            <BaseInput
              key={key}
              forwardRef={(elementRef) => {
                refs.current[index] = elementRef;
              }}
              isActive={activeInput === index}
              index={index}
              value={inputValue}
              onActiveChange={(nextIndex) => {
                refs.current?.[nextIndex]?.focus();
                setActiveInput(nextIndex);
              }}
              onChange={_onChange}
              textAlign="center"
              fontWeight="500"
              fontSize="$display-lg"
              {...rest}
              error={error}
              {...getDimentions}
            />
          );
        })}
      </XStack>
      <Subtext subtext={subtext} error={error} />
    </>
  );
};

export const OTPInput = forwardRef(_OTP);

import React from 'react';

import {YStack} from 'tamagui';

import {getLocalizedText} from '../../LocalizedText';
import {NoDataMessage} from '../../NoDataMessage';
import {Popover} from '../../Popover';
import {SelectOption} from '../components/SelectOption';
import {SelectWrapper} from '../components/SelectWrapper';
import {Trigger} from '../components/Trigger';
import {useSelect} from '../hooks/useSelect';
import {SelectProps} from '../types';

export const WebSelect = ({
  options,
  value,
  onChange,
  placeholder,
  unstyled,
  children,
  subtext,
  error,
  required,
  prefix,
  suffix,
  disabled,
  trigger,
  label,
  open,
  onOpenChange,
  showSearch = false,
  loading,
  size,
  searchValue: externalSearchValue,
  onSearch: externalOnSearch,
  ...containerProps
}: SelectProps) => {
  const {
    isOpen,
    handleOpenChange,
    searchValue,
    filteredOptions,
    selectedOption,
    inputRef,
    onSearch,
    t,
    i18n,
    handleValueChange,
    triggerWidth,
    setTriggerWidth,
  } = useSelect({
    open,
    value,
    searchValue: externalSearchValue,
    options,
    showSearch,
    onOpenChange,
    onChange,
    onSearch: externalOnSearch,
  });

  return (
    <SelectWrapper label={label} subtext={subtext} error={error} required={required} containerProps={containerProps}>
      <Popover
        hideArrow
        onOpenChange={handleOpenChange}
        triggerProps={{
          width: '100%',
        }}
        closable
        open={isOpen}
        contentProps={{
          marginVertical: 5,
          padding: 0,
          borderRadius: '$rounded-lg',
          overflow: 'hidden',
        }}
        trigger={
          <>
            {trigger ? (
              trigger
            ) : (
              <Trigger
                inputRef={inputRef}
                placeholder={getLocalizedText({value: placeholder, t, i18n})}
                disabled={disabled}
                showSearch={showSearch}
                prefix={selectedOption?.prefix || prefix}
                error={error}
                value={isOpen && showSearch ? searchValue : getLocalizedText({value: selectedOption?.label, t, i18n})}
                onSearch={onSearch}
                suffix={suffix}
                unstyled={unstyled}
                loading={loading}
                size={size}
                onSetTriggerWidth={setTriggerWidth}
              />
            )}
          </>
        }>
        <YStack
          borderRadius="$rounded-md"
          backgroundColor="$bg-primary"
          borderStyle="solid"
          borderWidth={1}
          borderColor="$border-secondary"
          hoverStyle={null}
          paddingVertical="$spacing-xs"
          paddingHorizontal="$spacing-sm"
          width={triggerWidth}
          maxHeight={300}
          overflow="scroll">
          {!filteredOptions?.length && !children && <NoDataMessage hasNoData hideRipples />}
          {filteredOptions?.map((option, i) => (
            <SelectOption
              key={`${option.value}-${i}`}
              option={option}
              isSelected={option.value === selectedOption?.value}
              onChangeSelection={() => handleValueChange(option.value)}
            />
          ))}
          {children}
        </YStack>
      </Popover>
    </SelectWrapper>
  );
};

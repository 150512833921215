import React from 'react';

import {ColorTokens} from 'tamagui';

import {useTokenColor} from '../../../utils';
import {SocialButtonTheme, SocialIconState, SvgSocialIconProps} from '../types';

export const XIcon: React.FC<SvgSocialIconProps> = ({theme = 'brand', state = 'default', ...props}) => {
  const _themestateColors: {
    [theme in SocialButtonTheme]?: {[state in SocialIconState]?: ColorTokens};
  } = {
    gray: {
      default: '$fg-quaternary',
      hover: '$fg-quaternary-hover',
    },
    brand: {
      default: '$fg-white',
      hover: '$fg-white',
    },
    color: {
      default: '$social-icon-fg-x',
      hover: '$social-icon-fg-x',
    },
  };
  const _stateColors = _themestateColors?.[theme];
  const _colorToken = _stateColors?.[state];
  const _iconColor = useTokenColor(_colorToken ? _colorToken : '$fg-white');

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9455 23L10.396 15.0901L3.44886 23H0.509766L9.09209 13.2311L0.509766 1H8.05571L13.286 8.45502L19.8393 1H22.7784L14.5943 10.3165L23.4914 23H15.9455ZM19.2185 20.77H17.2398L4.71811 3.23H6.6971L11.7121 10.2532L12.5793 11.4719L19.2185 20.77Z"
        fill={_iconColor}
      />
    </svg>
  );
};

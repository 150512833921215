import React, {ReactNode} from 'react';

import {Typography} from '../Typography';
import {ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

export type ListItemProps = ViewProps & {
  prefix?: ReactNode;
  suffix?: ReactNode;
  title?: ReactNode;
  subTitle?: ReactNode;
};

export const ListItem = ({prefix, suffix, title, subTitle, disabled, ...props}: ListItemProps) => {
  const hoverStyle: ViewProps = disabled ? {} : {backgroundColor: '$bg-active'};

  return (
    <XStack
      paddingVertical="$spacing-md"
      paddingHorizontal="$spacing-md"
      gap="$spacing-md"
      borderRadius="$rounded-sm"
      minHeight={44}
      cursor="pointer"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      backgroundColor={disabled ? '$bg-disabled' : '$bg-primary'}
      hoverStyle={hoverStyle}
      {...props}>
      {prefix}
      <YStack flex={1}>
        <Typography color={disabled ? '$text-disabled' : '$text-primary'}>{title}</Typography>
        {subTitle && <Typography variant="caption">{subTitle}</Typography>}
      </YStack>
      {suffix}
    </XStack>
  );
};

import {useEffect, useMemo, useState} from 'react';

import {MultiSelectProps} from '../types';

export const useSelectValues = ({value, onChange, options}: MultiSelectProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(value ? [...value] : []);

  const isSelected = (value: string) => selectedValues?.some((_value) => _value === value);

  useEffect(() => {
    !value?.length && setSelectedValues([]);
  }, [value]);

  const onChangeSelection = (value: string) => {
    const checked = !isSelected(value);
    const newSelectedValues = checked
      ? [...selectedValues, value]
      : selectedValues.filter((_value) => _value !== value);
    setSelectedValues(newSelectedValues);

    const selectedOptions = options?.filter((option) => {
      return newSelectedValues.includes(option.value);
    });

    onChange?.(newSelectedValues, selectedOptions);
  };

  const selectedOptions = useMemo(
    () => options?.filter((option) => selectedValues.includes(option.value)),
    [options, selectedValues],
  );

  const onReset = () => {
    setSelectedValues([]);
    onChange?.([]);
  };

  return {selectedOptions, isSelected, onChangeSelection, onReset};
};

import React, {forwardRef, useMemo, useState} from 'react';

import {IconType} from '../../types/IconType';
import {Button} from '../Button';
import {IconView} from '../IconView';

import {AppleIcon} from './icons/Apple';
import {DribbleIcon} from './icons/Dribble';
import {FaceBookIcon} from './icons/Facebook';
import {FigmaIcon} from './icons/Figma';
import {GoogleIcon} from './icons/Google';
import {XIcon} from './icons/X';
import {getSocialButtonStyle} from './styles';
import {SocialButtonProps, Social, SvgSocialIconProps} from './types';

export * from './types';

const _SocialButton = (
  {social = 'google', theme = 'brand', onHoverIn, onHoverOut, disabled, children, ...rest}: SocialButtonProps,
  ref,
) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const socialIcnos: {[key in Social]?: IconType} = useMemo(() => {
    let _theme = theme;
    if (disabled) {
      _theme = 'gray';
    }

    const iconsProps: SvgSocialIconProps = {
      theme: _theme,
      state: isButtonHovered ? 'hover' : 'default',
      opacity: disabled ? 0.5 : 1,
    };

    return {
      apple: () => <AppleIcon {...iconsProps} />,
      dribble: () => <DribbleIcon {...iconsProps} />,
      facebook: () => <FaceBookIcon {...iconsProps} />,
      google: () => <GoogleIcon {...iconsProps} />,
      figma: () => <FigmaIcon {...iconsProps} />,
      x: () => <XIcon {...iconsProps} />,
    };
  }, [theme, isButtonHovered, disabled]);

  return (
    <Button
      ref={ref}
      {...getSocialButtonStyle(theme, social, disabled)}
      prefix={<IconView icon={socialIcnos[social]} />}
      onHoverIn={(e) => {
        setIsButtonHovered(true);
        onHoverIn?.(e);
      }}
      onHoverOut={(e) => {
        setIsButtonHovered(false);

        onHoverOut?.(e);
      }}
      disabled={disabled}
      {...rest}
      suffix={null}
      hierarchy="primary"
      size="lg"
      destructive={false}>
      {!!children && children}
    </Button>
  );
};

export const SocialButton = forwardRef(_SocialButton);

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Card, CardProps} from '../../../../Card';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {CardPropsBuilder} from './PropsBuilder';

export const Component = forwardRef((props, ref) => {
  return <Card {...props} ref={ref} />;
});

export const CardComponent = Component;
Component.displayName = 'CardElement';

export const CardElement: BlockDefinition<CardProps> = {
  name: coreScopedKey('blocks.CardElement'),
  version: 1,
  id: 'CardElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: CardComponent,
  Icon: 'LoyaltyCardIcon',
  propsBuilder: [CardPropsBuilder, LayoutPropsBuilder],
};

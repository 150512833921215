import React, {forwardRef, useMemo} from 'react';

import {YStack as BaseYStack, YStackProps as BaseYStackProps} from 'tamagui';

import {SHADOW_BASE} from '../../lib/tamagui/constant';
import {useDirectionAwareProps} from '../../utils/useDirectionAwareProps';

export interface YStackProps extends BaseYStackProps {
  shadow?: keyof typeof SHADOW_BASE;
}

export const _YStack = ({shadow, ...props}: YStackProps, ref) => {
  const _props = useDirectionAwareProps(props);
  const shadowStyle = useMemo(() => (shadow ? SHADOW_BASE[shadow] : {}), [shadow]);

  return <BaseYStack ref={ref} {..._props} {...shadowStyle} />;
};

export const YStack = forwardRef(_YStack);

import React from 'react';

import {Dimensions} from 'react-native';
import {Adapt, Dialog as BaseDialog, Sheet} from 'tamagui';

import {AppletContext, useAppletContext} from '../../context/AppletContext';
import {useAppDirection} from '../../utils/useAppDirection';

import {DialogContent} from './components/DialogContent';
import {DialogProps} from './types';

const screenWidth = Dimensions.get('window').width;
export const Dialog = ({
  trigger,
  title,
  header,
  footer,
  content,
  hideFooter,
  width,
  sideBarWidth = '50%',
  modelHeight = 'full',
  height,
  contentProps,
  triggerProps,
  sideBarMode = true,
  onChange,
  onAccept,
  onCancel,
  onClose,
  ...props
}: DialogProps) => {
  const appletContext = useAppletContext();
  const {isRTL} = useAppDirection();

  const sidebarWidthInPixels = (screenWidth * parseFloat(sideBarWidth)) / 100;
  const enterStyles = isRTL ? {x: -sidebarWidthInPixels, opacity: 0} : {x: sidebarWidthInPixels, opacity: 0};

  const dialogContent = (
    <DialogContent
      header={header}
      footer={footer}
      title={title}
      contentProps={contentProps}
      content={content}
      hideFooter={hideFooter}
      onCancel={onCancel}
      onAccept={onAccept}
      onClose={onClose}
    />
  );

  return (
    <BaseDialog modal onOpenChange={(open) => (onChange ? onChange(open) : onClose?.())} {...props}>
      <BaseDialog.Trigger {...triggerProps} asChild>
        {trigger}
      </BaseDialog.Trigger>

      <Adapt when="sm" platform="touch">
        <Sheet
          snapPoints={[parseFloat(modelHeight === 'full' ? '95%' : modelHeight), 90]}
          zIndex={5}
          modal
          dismissOnSnapToBottom>
          <Sheet.Frame padding={0} space height="50%">
            <AppletContext.Provider value={appletContext}>
              <Adapt.Contents />
            </AppletContext.Provider>
          </Sheet.Frame>
          <Sheet.Overlay zIndex={5} position="absolute" />
        </Sheet>
      </Adapt>

      <BaseDialog.Portal>
        <AppletContext.Provider value={appletContext}>
          <BaseDialog.Overlay
            key="overlay"
            animation="quick"
            position={sideBarMode ? 'relative' : 'absolute'}
            width="100%"
            height="100%"
            opacity={0.5}
            enterStyle={{opacity: 0}}
            exitStyle={{opacity: 0}}
          />
          {sideBarMode ? (
            <BaseDialog.Content
              borderRadius="$3"
              position="absolute"
              style={{[isRTL ? 'left' : 'right']: 0}}
              top={0}
              animation="medium"
              padding={0}
              width={sideBarWidth}
              height="100%"
              key="content"
              backgroundColor="$background"
              enterStyle={enterStyles}
              exitStyle={enterStyles}>
              {dialogContent}
            </BaseDialog.Content>
          ) : (
            <BaseDialog.Content
              position="relative"
              borderRadius="$3"
              padding={0}
              width={width || 700}
              height={height}
              key="content"
              animation={['quick', {opacity: {overshootClamping: true}}]}
              enterStyle={{x: 0, y: -20, opacity: 0, scale: 0.9}}
              exitStyle={{x: 0, y: -20, opacity: 0, scale: 0.9}}>
              {dialogContent}
            </BaseDialog.Content>
          )}
        </AppletContext.Provider>
      </BaseDialog.Portal>
    </BaseDialog>
  );
};

import React, {ReactNode} from 'react';

import {Typography} from '../Typography';
import {View, ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {getCheckedStyle, getDisabledStyle, getSubTitleStyleBasedOnSize, getTitleStyleBasedOnSize} from './styles';

export type ListCardItemSize = 'sm' | 'md';
export type ListCardItemProps = {
  title?: ReactNode;
  subTitle?: ReactNode;
  description?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  size?: ListCardItemSize;
  checked?: boolean;
  disabled?: boolean;
};

export const ListCardItem: React.FC<ListCardItemProps> = ({
  description,
  title,
  subTitle,
  prefix,
  size = 'sm',
  disabled,
  suffix,
  checked,
}) => {
  const defaultCardStyles: ViewProps = {
    borderWidth: 1,
    borderColor: '$border-secondary',
    borderStyle: 'solid',
  };
  const focusedHoverStyle = !disabled ? {...defaultCardStyles, borderColor: '$border-brand'} : {};

  return (
    <View
      width="100%"
      {...defaultCardStyles}
      borderColor="transparent"
      borderRadius="$rounded-xl"
      {...getCheckedStyle(checked)}
      {...(checked ? {backgroundColor: '$border-brand'} : {})}
      {...(checked && getDisabledStyle(disabled))}
      {...(!!disabled ? {backgroundColor: '$bg-disabled-subtle'} : {})}
      hoverStyle={focusedHoverStyle}
      focusStyle={focusedHoverStyle}
      pressStyle={focusedHoverStyle}>
      <YStack
        {...defaultCardStyles}
        borderRadius="$rounded-xl"
        backgroundColor="$bg-primary"
        {...getCheckedStyle(checked)}
        {...getDisabledStyle(disabled)}>
        <XStack padding="$spacing-xl" gap="$spacing-lg" justifyContent="space-between">
          <XStack gap="$spacing-lg">
            {prefix && <View>{prefix}</View>}

            <View>
              {title && (
                <Typography {...getTitleStyleBasedOnSize(size)} color="$text-secondary">
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography {...getSubTitleStyleBasedOnSize(size)} color="$text-tertiary">
                  {subTitle}
                </Typography>
              )}
            </View>
          </XStack>
          {suffix && <View>{suffix}</View>}
        </XStack>
        {description && (
          <XStack
            padding="$spacing-xl"
            borderWidth={0}
            borderTopWidth={1}
            borderStyle="solid"
            {...(checked && !disabled ? {borderTopColor: '$border-brand'} : {borderTopColor: '$border-secondary'})}>
            <Typography color="$text-tertiary">{description}</Typography>
          </XStack>
        )}
      </YStack>
    </View>
  );
};

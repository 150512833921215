import React, {forwardRef, useMemo} from 'react';

import {TextArea as BaseTextArea, TextAreaProps as BaseTextAreaProps} from 'tamagui';

import {useTokenColor} from '../../utils';
import {InputProps, Label, Subtext} from '../Input';
import {inputDefaultStyles, getInputDisabledStyles, inputErrorStyles} from '../Input/styles';
import {Typography} from '../Typography';
import {View} from '../View';
import {XStack} from '../XStack';

export type TextAreaProps = Omit<InputProps, 'size' | 'prefix' | 'multiline' | 'addOnTextBefore' | 'addOnAfter'> &
  BaseTextAreaProps;

export const _TextArea = (
  {
    numberOfLines = 4,
    label,
    suffix,
    disabled,
    required,
    error = false,
    subtext,
    containerProps,
    ...props
  }: TextAreaProps,
  ref,
) => {
  const placeholderColor = useTokenColor('$text-placeholder');

  const textAreaStyles = useMemo(() => {
    const _disabledStyles = disabled ? getInputDisabledStyles() : {};
    const _errorStyles = error ? inputErrorStyles : {};

    return {
      ...inputDefaultStyles,
      flex: undefined,
      ..._disabledStyles,
      ..._errorStyles,
    };
  }, [disabled, suffix, error]);

  return (
    <View {...containerProps}>
      {label || suffix ? (
        <XStack gap="$spacing-xs" alignItems="center" marginBottom="$spacing-sm">
          {label ? <Label label={label} required={required} /> : null}
          {suffix ? (
            <Typography marginBottom="$spacing-sm" display="flex">
              {suffix}
            </Typography>
          ) : null}
        </XStack>
      ) : null}
      <BaseTextArea
        lineHeight="$2"
        verticalAlign="top"
        numberOfLines={numberOfLines}
        {...textAreaStyles}
        alignItems="flex-start"
        justifyContent="flex-start"
        paddingVertical="$spacing-lg"
        paddingHorizontal={14}
        placeholderTextColor={placeholderColor as any}
        disabled={disabled}
        {...props}
        ref={ref}
      />
      {subtext ? <Subtext subtext={subtext} error={error} /> : null}
    </View>
  );
};

export const TextArea = forwardRef(_TextArea);

import React from 'react';

import {AlertDialog} from 'tamagui';

import {Button} from '../Button';
import {IconView} from '../IconView';
import {Pressable} from '../Pressable';
import {CoreTrans} from '../ScopedTrans';
import {View} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {ModalTypeConfig} from './config/index';
import {ModalProps} from './types';

export const Modal = ({
  type,
  show,
  trigger,
  content,
  onAccept,
  onCancel,
  title,
  icon,
  cancelText,
  confirmText,
  footer,
}: ModalProps) => {
  const color = ModalTypeConfig[type || '']?.color;
  const defaultIcon = ModalTypeConfig[type || '']?.icon;
  const lightColor = ModalTypeConfig[type || '']?.lightColor;

  return (
    <AlertDialog open={show} native>
      <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          key="overlay"
          animation="quick"
          opacity={0.5}
          enterStyle={{opacity: 0}}
          exitStyle={{opacity: 0}}
        />
        <AlertDialog.Content
          bordered
          elevate
          key="content"
          overflow="hidden"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{x: 0, y: -20, opacity: 0, scale: 0.9}}
          exitStyle={{x: 0, y: 10, opacity: 0, scale: 0.95}}
          x={0}
          scale={1}
          opacity={1}
          y={0}>
          <YStack
            alignSelf="flex-start"
            alignItems="flex-start"
            minHeight={240}
            width={400}
            space
            $sm={{
              width: '$20',
            }}>
            <XStack width="100%" justifyContent="space-between" alignItems="center">
              <YStack position="relative" alignItems="center" justifyContent="center">
                {[1, 2, 3, 4].map((_, index) => {
                  const size = 48 + (index + 1) * 24;
                  return (
                    <View
                      key={index}
                      position="absolute"
                      height={size}
                      width={size}
                      borderRadius={size / 2}
                      borderWidth={1}
                      borderColor="$button-secondary-border"
                    />
                  );
                })}

                <View
                  alignItems="center"
                  justifyContent="center"
                  height={48}
                  width={48}
                  borderRadius="$rounded-full"
                  marginVertical="$1"
                  backgroundColor={lightColor || '$bg-brand-primary'}>
                  <IconView icon={icon || defaultIcon} color={color || '$brand-700'} size={20} />
                </View>
              </YStack>

              <AlertDialog.Cancel asChild>
                <Pressable onPress={onCancel} justifyContent="flex-end">
                  <IconView color="$fg-quinary" size={20} icon="Cancel01Icon" />
                </Pressable>
              </AlertDialog.Cancel>
            </XStack>

            <YStack alignItems="flex-start" justifyContent="flex-start" flex={1}>
              <AlertDialog.Title marginVertical="$0.5" fontSize="$text-lg" fontWeight="600" color="$text-primary">
                {title}
              </AlertDialog.Title>
              <AlertDialog.Description lineHeight="$6" fontSize="$text-sm" fontWeight="400" color="$text-tertiary">
                {content}
              </AlertDialog.Description>
            </YStack>

            {footer ? (
              <View width="100%">{footer}</View>
            ) : (
              <XStack gap="$3" width="100%" justifyContent="center">
                <AlertDialog.Cancel asChild>
                  <Button hierarchy="secondary-gray" size="lg" onPress={onCancel}>
                    {cancelText || <CoreTrans i18nKey="action.cancel" />}
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <Button onPress={onAccept} size="lg" hierarchy="primary" destructive={type === 'danger'}>
                    {confirmText || <CoreTrans i18nKey="action.accept" />}
                  </Button>
                </AlertDialog.Action>
              </XStack>
            )}
          </YStack>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>
  );
};

import React, {Component, ReactNode} from 'react';

import {Button} from '../Button';
import {IconView} from '../IconView';

export interface UploadButtonProps {
  value?: File | string;
  children?: ReactNode;
  multiple?: boolean;
  accept?: string;
  onChange?: (file: File) => void;
  disabled?: boolean;
  loading?: boolean;
}

export class UploadButton extends Component<UploadButtonProps> {
  fileInput = React.createRef<HTMLInputElement>();

  render() {
    const {children, multiple, accept, onChange} = this.props;

    return (
      <>
        <input
          ref={this.fileInput}
          onChange={(e) => {
            const file = e.target?.files?.[0];
            file && onChange?.(file);

            if (this.fileInput.current) {
              this.fileInput.current.value = '';
            }
          }}
          type="file"
          style={{display: 'none'}}
          multiple={multiple}
          accept={accept}
          disabled={this.props.disabled}
        />
        <Button
          hierarchy="secondary-color"
          onPress={(event) => {
            event?.stopPropagation();
            event?.preventDefault();

            this.fileInput.current?.click();
          }}
          disabled={this.props.disabled}
          loading={this.props.loading}
          size="sm"
          prefix={<IconView icon="CloudUploadIcon" />}>
          {children}
        </Button>
      </>
    );
  }
}

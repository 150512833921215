import React, {useMemo} from 'react';

import {ScrollView, View, StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {useTheme} from 'tamagui';

import {useResponsiveValue} from '../../utils';
import {Typography} from '../Typography';

// todo: split and document components

interface PageHeaderProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
}

type PageContainerProps = PageHeaderProps & {
  children?: any;
  noPadding?: boolean;
  scrollable?: boolean;
  styles?: StyleProp<ViewStyle>;
};

export function PageHeader({title, actions}: PageHeaderProps): any {
  if (!title && !actions) return null;

  return (
    <View style={{marginBottom: 20, justifyContent: 'space-between', flexDirection: 'row'}}>
      <View>
        {title ? (
          <Typography variant="h5" fontWeight="700">
            {title}
          </Typography>
        ) : null}
      </View>

      {actions}
    </View>
  );
}

export function PageContainer({
  children,
  noPadding,
  scrollable = true,
  styles: extraStyles,
  ...headerProps
}: PageContainerProps): any {
  const theme = useTheme();

  const screenPaddingVertical = useResponsiveValue({$sm: 16, $md: 26, $lg: 26});
  const screenPaddingHorizontal = useResponsiveValue({$sm: 16, $md: 36, $lg: 64});

  const containerStyles = useMemo(
    () => styles({theme, noPadding, screenPaddingVertical, screenPaddingHorizontal}),
    [theme, noPadding, screenPaddingVertical],
  );

  if (scrollable) {
    return (
      <ScrollView contentContainerStyle={[containerStyles.container, extraStyles]}>
        <PageHeader {...headerProps} />
        {children}
      </ScrollView>
    );
  }

  return (
    <View style={[containerStyles.container, extraStyles]}>
      <PageHeader {...headerProps} />
      {children}
    </View>
  );
}

const styles = (props) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      backgroundColor: props.theme.background.val,
      paddingVertical: props.noPadding ? 0 : props.screenPaddingVertical,
      paddingHorizontal: props.noPadding ? 0 : props.screenPaddingHorizontal,
      paddingBottom: props.noPadding ? 0 : 15,
    },
  });

export function AppScreen(props: PageContainerProps): any {
  return <PageContainer noPadding {...props} />;
}

export function MiniScreen(props: PageContainerProps): any {
  const isMobile = useResponsiveValue({$sm: true, $md: false});

  return <PageContainer {...props} noPadding={!isMobile} />;
}

import React, {useState} from 'react';

import {ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {CarouselButton} from './components/CarouselButton';
import ProgressStepper from './components/ProgressSteps';

export type CarouselProps = {
  size: number;
  onPageChange?: (index: number) => void;
  currentPage?: number;
  children: React.ReactNode | ((currentPage: number) => React.ReactNode);
  showPropgressSteps?: boolean;
} & ViewProps;

export const Carousel = ({currentPage, size, onPageChange, children, showPropgressSteps, ...props}: CarouselProps) => {
  const [selectedPage, setSelectedPage] = useState(currentPage || 0);

  const onPageChanged = (page: number) => {
    onPageChange?.(page);
    setSelectedPage(page);
  };

  return (
    <YStack width="100%">
      <XStack justifyContent="space-between" alignItems="center" marginTop="$1" width="100%" {...props}>
        <CarouselButton
          icon="ArrowLeft01Icon"
          onPress={() => onPageChanged(selectedPage - 1)}
          isHidden={selectedPage === 0}
        />
        {typeof children === 'function' ? children(selectedPage) : children}
        <CarouselButton
          icon="ArrowRight01Icon"
          onPress={() => onPageChanged(selectedPage + 1)}
          isHidden={selectedPage >= size - 1}
        />
      </XStack>
      {showPropgressSteps && (
        <XStack justifyContent="center" alignItems="center">
          <ProgressStepper
            currentStep={currentPage}
            steps={size}
            onStepClick={(stepClicked) => {
              onPageChanged(stepClicked);
            }}
          />
        </XStack>
      )}
    </YStack>
  );
};

export const FALLBACK_SCALE = 0.57;

type AvatarSizeConfig = {
  size: number;
  borderWidth: number;
};

export type AvatarSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const avatarSizes: Record<AvatarSizes, AvatarSizeConfig> = {
  xs: {
    size: 24,
    borderWidth: 0.5,
  },
  sm: {
    size: 32,
    borderWidth: 0.5,
  },
  md: {
    size: 40,
    borderWidth: 0.75,
  },
  lg: {
    size: 48,
    borderWidth: 0.75,
  },
  xl: {
    size: 56,
    borderWidth: 0.75,
  },
  '2xl': {
    size: 64,
    borderWidth: 0.75,
  },
} as const;

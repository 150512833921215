import {config as baseConfig} from '@tamagui/config';
import {createTamagui} from 'tamagui';

import {Theme} from '../../types';
import {generateShades} from '../../utils';

import {DARK_COLORS_THEME, LIGHT_COLORS_THEME} from './constant';
import {fonts} from './fonts';
import {tintTamaguiTheme} from './themes';
import {tokens} from './tokens';

const config = {
  ...baseConfig,
  shorthands: {},
  fonts,
  tokens,
  media: {
    xxs: {maxWidth: 390},
    xs: {maxWidth: 660},
    sm: {maxWidth: 800},
    md: {maxWidth: 1020},
    lg: {maxWidth: 1280},
    xl: {maxWidth: 1650},

    gtXs: {minWidth: 661},
    gtSm: {minWidth: 801},
    gtMd: {minWidth: 1021},
    gtLg: {minWidth: 1281},
    gtXl: {minWidth: 1651},

    esm: {minWidth: 661, maxWidth: 800},
    emd: {minWidth: 801, maxWidth: 1020},
    elg: {minWidth: 1021, maxWidth: 1280},
    exl: {minWidth: 1280},
  },
  themes: {
    ...baseConfig.themes,
    [Theme.light]: {
      ...baseConfig.themes.light,
      ...LIGHT_COLORS_THEME,
    },
    [Theme.dark]: {
      ...baseConfig.themes.dark,
      ...DARK_COLORS_THEME,
    },
    [Theme.light_translucent]: {
      background: 'white',
    },
    [Theme.dark_translucent]: {
      background: '#242526',
    },
  },
};

export const tamaguiConfig = createTamagui(config);

export type TamaConfigType = typeof tamaguiConfig;

export interface CustomTamaguiConfig {
  brandColor?: string;
}

export const createCustomTamaguiConfig = ({brandColor}: CustomTamaguiConfig) => {
  if (!brandColor) {
    brandColor = 'gray';
  }

  const brandShades = generateShades(brandColor);
  const brandShadesByName = {
    'brand-25': brandShades[0],
    'brand-50': brandShades[1],
    'brand-100': brandShades[2],
    'brand-200': brandShades[3],
    'brand-300': brandShades[4],
    'brand-400': brandShades[5],
    'brand-500': brandShades[6],
    'brand-600': brandShades[7],
    'brand-700': brandShades[8],
    'brand-800': brandShades[9],
    'brand-900': brandShades[10],
    'brand-950': brandShades[11],
  };

  // replace brand tokens with generated shades
  const themes = {...config.themes};

  Object.keys(themes).forEach((theme) => {
    themes[theme] = {...themes[theme]};

    Object.keys(themes[theme]).forEach((color) => {
      const replaceColor = brandShadesByName[themes[theme][color]];

      if (replaceColor) {
        themes[theme][color] = replaceColor;
      }
    });
  });

  const baseConfig = createTamagui({...config, themes});

  return tintTamaguiTheme(baseConfig, brandColor);
};

declare module 'tamagui' {
  interface TamaguiCustomConfig extends TamaConfigType {}
}

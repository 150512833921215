import {Platform} from 'react-native';
import {styled, ThemeableStack} from 'tamagui';

const boxSizing = Platform.OS === 'web' ? {boxSizing: 'border-box'} : {};
export const Grid = styled(ThemeableStack, {
  variants: {
    row: {
      false: {
        flexDirection: 'column',
        ...boxSizing,
      },
      true: {
        flexDirection: 'row',
        ...boxSizing,
      },
      sm: {
        $gtSm: {
          flexDirection: 'row',
          ...boxSizing,
        },
      },
      md: {
        $gtMd: {
          flexDirection: 'row',
          ...boxSizing,
        },
      },
      lg: {
        $gtLg: {
          flexDirection: 'row',
          ...boxSizing,
        },
      },
    },
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
      sm: {
        $gtSm: {
          flexWrap: 'wrap',
        },
      },
      md: {
        $gtMd: {
          flexWrap: 'wrap',
        },
      },
      lg: {
        $gtLg: {
          flexWrap: 'wrap',
        },
      },
    },
    container: {
      true: {
        flexGrow: 1,
        minWidth: 0,
      },
      false: {
        flexGrow: 0,
      },
    },
    centered: {
      ':boolean': (bool) => {
        if (bool) {
          return {
            alignItems: 'center',
            justifyContent: 'center',
          };
        }
      },
    },
    xs: {
      true: {
        $gtXs: {
          flexGrow: 1,
          maxWidth: '100%',
          minWidth: 0,
        },
      },

      ':number': (nr) => ({
        $gtXs: {
          width: `${(nr / 12) * 100}%`,
          ...boxSizing,
        },
      }),
    },
    sm: {
      true: {
        $gtSm: {
          flexGrow: 1,
          maxWidth: '100%',
          minWidth: 0,
        },
      },

      ':number': (nr) => ({
        $gtSm: {
          width: `${(nr / 12) * 100}%`,
          ...boxSizing,
        },
      }),
    },
    md: {
      true: {
        $gtMd: {
          flexGrow: 1,
          maxWidth: '100%',
          minWidth: 0,
        },
      },

      ':number': (nr) => ({
        $gtMd: {
          width: `${(nr / 12) * 100}%`,
          ...boxSizing,
        },
      }),
    },
    lg: {
      true: {
        $gtLg: {
          flexGrow: 1,
          maxWidth: '100%',
          minWidth: 0,
        },
      },

      ':number': (nr) => ({
        $gtLg: {
          width: `${(nr / 12) * 100}%`,
          ...boxSizing,
        },
      }),
    },
    gapX: {
      ':number': (nr) => ({
        marginLeft: -nr / 2,
        marginRight: -nr / 2,
      }),
    },
    gapY: {
      ':number': (nr) => ({
        marginTop: -nr / 2,
        marginBottom: -nr / 2,
      }),
    },
    paddingX: {
      ':number': (nr) => ({
        paddingLeft: nr / 2,
        paddingRight: nr / 2,
      }),
    },
    paddingY: {
      ':number': (nr) => ({
        paddingTop: nr / 2,
        paddingBottom: nr / 2,
      }),
    },
  } as const,
});

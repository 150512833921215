import React from 'react';

import {Platform} from 'react-native';

import {MobileSelect} from './components/MobileSelect';
import {WebSelect} from './components/WebSelect';
import {MultiSelectProps} from './types';

// TODO: refactor to one implemntation for web and mobile
export function MultiSelect(props: MultiSelectProps) {
  if (Platform.OS === 'web') {
    return <WebSelect {...props} />;
  }

  return <MobileSelect {...props} />;
}

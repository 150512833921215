import {TypographyProps} from '../Typography';
import {ViewProps} from '../View';

import {ListCardItemSize} from './index';

export const getTitleStyleBasedOnSize = (size: ListCardItemSize): TypographyProps => {
  switch (size) {
    case 'md':
      return {fontSize: '$text-md', fontWeight: '500'};
    default:
      return {fontSize: '$text-sm', fontWeight: '500'};
  }
};

export const getSubTitleStyleBasedOnSize = (size: ListCardItemSize): TypographyProps => {
  switch (size) {
    case 'md':
      return {fontSize: '$text-md', fontWeight: '400'};
    default:
      return {fontSize: '$text-sm', fontWeight: '400'};
  }
};

export const getCheckedStyle = (checked?: boolean): ViewProps => {
  return checked ? {borderColor: '$border-brand', borderWidth: 1, borderStyle: 'solid'} : {};
};

export const getDisabledStyle = (disabled?: boolean): ViewProps => {
  return disabled
    ? {
        backgroundColor: '$bg-disabled-subtle',
        borderColor: '$border-disabled-subtle',
        borderWidth: 1,
        borderStyle: 'solid',
      }
    : {};
};

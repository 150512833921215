import React from 'react';

import {IconType} from '../../types';
import {useAppDirection} from '../../utils';
import {Badge} from '../Badge';
import {BidirectionalIcon} from '../BidirectionalIcon';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

import {BadgeGroupProps} from './types';
import {getBadgeStyles} from './utils/badgeGroupStyles';
import {getBadgeSizeAccordingToGroupSize, getBadgetypeAccordingToTheme} from './utils/badgeStyles';

export * from './types';

export const BadgeGroup = ({
  label,
  size = 'md',
  color = 'brand',
  theme = 'light',
  badgePosition = 'leading',
  badgeProps,

  suffixIcon,
  prefixIcon,
  ...props
}: BadgeGroupProps) => {
  const {isRTL} = useAppDirection();
  const badgeStyles = getBadgeStyles(theme, color, size, badgePosition, isRTL);

  const getSuffix = (): IconType => {
    if (suffixIcon) {
      return suffixIcon;
    }

    return 'ArrowRight02Icon';
  };

  const getPrefix = (): IconType => {
    if (prefixIcon) {
      return prefixIcon;
    }

    return 'RadioButtonIcon';
  };

  const renderTrailingBadge = () => {
    if (badgePosition === 'leading' || !badgePosition) return null;

    return renderBadge({
      suffix: getSuffix(),
    });
  };

  const renderLeadingBadge = () => {
    if (badgePosition === 'trailing' || !badgePosition) return null;

    return renderBadge({
      prefix: theme === 'modern' ? getPrefix() : undefined,
    });
  };

  const renderBadge = ({prefix, suffix}: {prefix?: IconType; suffix?: IconType}) => {
    if (!badgeProps) return null;
    let _color = color;

    if (badgePosition === 'trailing' && theme === 'modern') {
      _color = 'gray';
    }

    return (
      <Badge
        type={getBadgetypeAccordingToTheme(theme)}
        size={getBadgeSizeAccordingToGroupSize(size)}
        color={_color}
        backgroundColor="$bg-primary"
        suffix={suffix && <BidirectionalIcon icon={suffix} />}
        prefix={prefix && <IconView icon={prefix} />}
        {...badgeProps}
      />
    );
  };

  return (
    <XStack padding="$spacing-xs" cursor="pointer" {...badgeStyles.container} paddingInlineEnd={70} {...props}>
      {badgePosition === 'trailing' && theme === 'modern' && (
        <IconView icon={getPrefix()} size={16} color={badgeStyles.icon.color} />
      )}
      {renderLeadingBadge()}
      <Typography {...badgeStyles.text}>{label}</Typography>
      {renderTrailingBadge()}
      {badgePosition === 'leading' && (
        <BidirectionalIcon
          icon={getSuffix()}
          size={16}
          color={theme === 'modern' ? '$fg-quaternary' : badgeStyles.icon.color}
        />
      )}
    </XStack>
  );
};

type ColorConfig = {
  selectedFirstOrLastColor: string;
  normalColor: string;
  selectedColor: string;
  rangeHoverColor: string;
  disabledColor: string;
};

type ColorResult = {
  background: string;
  foreground: string;
};

export const getColor = (
  isSelected: boolean,
  isSelectedStartOrEnd: boolean,
  isWithinHoverRange: boolean,
  isDisabled: boolean,
  isCurrentMonth: boolean,
) => {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }: ColorConfig): ColorResult => {
    const textColor = isCurrentMonth ? '$text-secondary' : '$text-disabled';

    if (isSelectedStartOrEnd) {
      return {background: selectedFirstOrLastColor, foreground: '$inverseTextColor'};
    }
    if (isSelected) {
      return {background: selectedColor, foreground: textColor};
    }
    if (isWithinHoverRange) {
      return {background: rangeHoverColor, foreground: textColor};
    }
    if (isDisabled) {
      return {background: disabledColor, foreground: '$text-disabled'};
    }
    return {background: normalColor, foreground: textColor};
  };
};

import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory, AppContentPage} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {ViewProps} from '../../../../View';
import {YStack} from '../../../../YStack';
import {LayoutPropsBuilder} from '../../../components';
import {migrateElement} from '../../../utils';

import {CURRENT_VERSION} from './constants';
import {migrations} from './utils/migrations';

export type PageElementProps = ViewProps;

const defaultProps: PageElementProps = {
  padding: 0,
  gap: 12,
  position: 'relative',
  minHeight: '100%',
  overflow: 'scroll',
  borderRadius: 8,
  backgroundColor: '$background',
};
export const Component = ({children, ...props}: PageElementProps, ref) => {
  return (
    <YStack {...props} ref={ref}>
      {children}
    </YStack>
  );
};

export const PageComponent = forwardRef(Component);

export const PageElement: BlockDefinition<PageElementProps, AppContentPage> = {
  name: coreScopedKey('blocks.PageElement'),
  version: CURRENT_VERSION,
  id: 'PageElement',
  category: BlockCategory.LAYOUT,
  props: defaultProps,
  children: [],
  Component: PageComponent,
  Icon: 'LayoutTopIcon',
  propsBuilder: [LayoutPropsBuilder],
  migrate: (node) => migrateElement(migrations, node, CURRENT_VERSION),
};
